import React, {useEffect, useState} from 'react';
import styles from "./../styles.module.scss";
import AdminStatisticsPage, {METRICS_NAMES, SimpleGraphDataRequest} from "../index";
import AppGraph from "../../../components/AppGraph";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {useSelector} from "react-redux";

const StatisticProductsPage = ({history}) => {

    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])

    const {REQUEST} = RequestWrapper();

    const label = "Продукты";

    // const [time, setTime] = useState(7);
    // const SetTime = (t) => {
    //     let storeTime = useSelector(state => state.statistics.time);
    //
    // }
    const time = useSelector(state => state.statistics.time);

    const clearData = () => {
        const clearAll = () => {
            setData3([]);
            setData4([]);
        }
        return Promise.resolve(clearAll())
    }

    useEffect(() => {
        clearData().then(res => {
            SimpleGraphDataRequest(REQUEST, METRICS_NAMES[2], setData3, label, time);
            SimpleGraphDataRequest(REQUEST, METRICS_NAMES[3], setData4, label, time);
        });
    }, [time])

    return <AdminStatisticsPage history={history} time={time} screenId={1}>
    <div className={styles.graphics_container}>

        <div className={styles.graphic_elem}>
            <AppGraph data={data3}
                      name={METRICS_NAMES[2].bigLabel}
                      primaryAxisType="time"
            />
        </div>
        <div className={styles.graphic_elem}>
            <AppGraph data={data4}
                      name={METRICS_NAMES[3].bigLabel}
                      primaryAxisType="time"
            />
        </div>
    </div>
    </AdminStatisticsPage>
}

export default StatisticProductsPage;