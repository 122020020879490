export const THEME = {
    MAIN_COLOR: "#303f9f",
    DANGER_COLOR: "#D81B60",
    NOT_ACTIVE: "#4d4d4d",

    ORANGE_500: "#FF9900",
    ORANGE_400: "#FFBA3F",
    ORANGE_300: "#FFCE66",
    ORANGE_200: "#FFE299",

    RED_500: "#FF675B",
    RED_400: "#FF9783",
    RED_300: "#FFB49C",
    RED_200: "#FFD2BD",

    BLUE_500: "#4A5784",
    BLUE_400: "#7988B5",
    BLUE_300: "#A0B0DA",
    BLUE_200: "#C8D4F2",

    GRAY_500: "#2F4858",
    GRAY_400: "#647E8F",
    GRAY_300: "#AFC1CC",
    GRAY_200: "#e6eef2",
    GRAY_50: "#f5fafc",

    BACKGROUND_COLOR: "#f5fafc",

    GREEN_100: "#beffe6",
    GREEN_200: "#58FFB8",
    GREEN_500: "#00D077",
    PINK_200: "#FABFC5",
    PINK_500: "#D6578B",

    MAIN_FONT: "PT-Sans",
    MAIN_FONT_BOLD: "PT-Sans-bold",
    TEXT_FONT_BOLD: "Roboto-Bold",
    TEXT_FONT_ITALIC: "Roboto-Italic",
    TEXT_FONT_BOLD_ITALIC: "Roboto-BoldItalic",
    TEXT_FONT: "Roboto-Regular",
    TEXT_SIZE: 12,
};