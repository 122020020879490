import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import {AppModal} from "../../components/AppModal";
import RequestWrapper from "../../helpers/RequestWrapper";
import {MAIN_URL, PERIODS, PERSONS, SECTIONS, TAGS} from "../../constants";
import AppCircularProgress from "../../components/UI/AppCircularProgress";
import {AppWhiteBlock} from "../../components/AppWhiteBlock";
import AppButton from "../../components/AppButton";

const GetStatistics = ({REQUEST, type = "common", person = "", section = "", period = "", handler = () => {}}) => {
    let params = `type=${type}`
    if (person && person in PERSONS) {
        params += `&person=${person}`
    } else if (period && period in PERIODS) {
        params += `&period=${period}`
    } else if (section && section in SECTIONS) {
        params += `&section=${section}`
    }
    return REQUEST.GET({request: "statistics/progress/?" + params})
}

const AddHeader = ({section, period, person}) => {
    try {
        if (person) {
            return PERSONS[person].name
        } else if (period) {
            return PERIODS[period].name
        } else if (section) {
            return SECTIONS[section].name
        }
    } catch (e) {
        return ""
    }
    return ""
}

const GetHeader = ({section, period, person}) => {
    let header = AddHeader({section, period, person})
    if (header) return header
    return "Прогресс"
}

const StatisticRow = ({text, meaning}) => (
    <div className={styles.row_div}>
        <h6 className={styles.row_text}>
            {text}
        </h6>
        <h6 className={styles.row_number}>
            {meaning}
        </h6>
    </div>
)

const GetName = (dict, name) => {
    try {
        return dict[name].name
    } catch (e) {
        return ""
    }
}

function StudentStatisticsPage({history, match, startURL = MAIN_URL.STATISTICS}) {

    const section = match.params.section;
    const period = match.params.period;
    const person = match.params.person;

    // useEffect(() => {
    //     if (section) {
    //         if (!section in Object.keys(SECTIONS)) {
    //             history.push(startURL)
    //         }
    //     }
    // }, [section])

    const [userType, setUserType] = useState("common");
        // location.state && location.state.type && location.state.type in ["common", "detail"] ?
        // location.state.type : "common";
    const {REQUEST} = RequestWrapper();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")

    const [data, setData] = useState([])
    const [tags, setTags] = useState([])
    const [questions, setQuestions] = useState([])

    const [questionModal, setQuestionModal] = useState(false)
    const [questionId, setQuestionId] = useState(-1)

    const [nextType, setNextType] = useState("")
    const [amount, setAmount] = useState(0)
    const [linearProgress, setLinearProgress] = useState(0)
    const [progress, setProgress] = useState(0)
    const [right, setRight] = useState(0)
    const [totalAttempts, setTotalAttempts] = useState(0)
    const [totalRight, setTotalRight] = useState(0)
    useEffect(() => {
        setLoading(true)
        console.log("Sec", section, ", period", period, ", person", person, ", TYPE", userType)

        if (section && !(Object.keys(SECTIONS).findIndex(s => s === section) >= 0) ||
            period && !(Object.keys(PERIODS).findIndex(s => s === period) >= 0) ||
            person && !(Object.keys(PERSONS).findIndex(s => s === person) >= 0)
        ) {
            history.push(startURL);
            setLoading(false)
            return () => {}
        }

        let addHeader = AddHeader({section, person, period})
        if (addHeader) {
            document.title = "Моя статистика | " + addHeader
        } else {
            document.title = "Моя статистика"
        }
        GetStatistics({REQUEST, section, person, period, type: userType}).then(res => {
            if (res.status !== 200) {
                setError("Ошибка загрузки статистики " + JSON.stringify(res.message))
                // setData(JSON.stringify(res.json))
            } else {
                setType(res.json.type)
                setAmount(res.json.amount)
                setProgress(res.json.progress)
                setLinearProgress(res.json.linear_progress)
                setRight(res.json.right)
                setTotalAttempts(res.json.total_attempts)
                setTotalRight(res.json.total_right)
                console.log("JSON", res.json)
                if (res.json.type === "common") {
                    setNextType(res.json.next_type)
                    setData(res.json.data.map(elem => ({...elem, percentage: Number(elem.progress)})))
                } else {
                    setTags(res.json.tags)
                    setQuestions(res.json.difficult.map(elem => {
                        let v_a;
                        if (typeof elem.valid_answers === 'object') {
                            v_a = elem.valid_answers
                        } else {
                            v_a = JSON.parse(elem.valid_answers)
                        }
                        return {...elem, valid_answers: v_a}
                    }))
                }
            }
            setLoading(false)
        })
    }, [section, period, person, userType])
    //useEffect(() => console.log("RERENDER"))
    const [type, setType] = useState("common")
    //const []
    const onNext = (id) => {
        console.log("ID", id, history.location.pathname + '/' + data[id][nextType])
        history.push(history.location.pathname + '/' + data[id][nextType])
    }

    const onPrev = (elem) => {
        if (elem === "person") {
            history.push(startURL + '/' + section + '/' + period + '/' + person)
        } else if (elem === 'period') {
            history.push(startURL + '/' + section + '/' + period)
        } else if (elem === 'section') {
            history.push(startURL + '/' + section)
        } else {
            history.push(startURL)
        }
    }

    return <div className={styles.screen}>
        {questions.length > 0 && <AppModal modalVisible={questionModal} setModalVisible={setQuestionModal}>
            <h4>Информация о задании</h4>
            <div className={styles.column_div}>
            <div>
                <h5>Вопрос</h5>
            <p>{questions.length > 0 && questionId >= 0 && questions[questionId].question}</p>
            </div>
            <div className={styles.row_div} style={{justifyContent: 'flex-start'}}>
                <p style={{fontWeight: 'bold', marginRight: '10px'}}>Верный ответ:</p>
                <p>{questions.length > 0 && questionId >= 0 && questions[questionId].valid_answers[0]}</p>
            </div>
                <div className={styles.row_div} style={{justifyContent: 'flex-start'}}>
                    <p style={{fontWeight: 'bold', marginRight: '10px'}}>Всего попыток:</p>
                    <p>{questions.length > 0 && questionId >= 0 && questions[questionId].attempts}</p>
                </div>
                <div className={styles.row_div} style={{justifyContent: 'flex-start'}}>
                    <p style={{fontWeight: 'bold', marginRight: '10px'}}>Попыток с правильным ответом:</p>
                    <p>{questions.length > 0 && questionId >= 0 && questions[questionId].successful_attempts}</p>
                </div>
            </div>
            <AppButton onClick={() => setQuestionModal(false)} type={"red"}>Закрыть</AppButton>
        </AppModal>}
        <div className={styles.head_line_div}>
            <div className={styles.head_history_div}>
                <div className={styles.div_header_elem} onClick={() => onPrev("all")}>
                    Общее
                </div>
                {section && <div className={styles.div_header_elem}
                                 style={{backgroundColor: '#d4e0f6'}}
                                 onClick={() => onPrev("section")}>
                    {GetName(SECTIONS, section)}
                </div>}
                {period && <div className={styles.div_header_elem} onClick={() => onPrev("period")}
                                style={{backgroundColor: '#e6ebfc'}}>
                    {GetName(PERIODS, period)}
                </div>}
                {person && <div className={styles.div_header_elem} onClick={() => onPrev("person")}
                                style={{backgroundColor: '#ebeffa'}}>
                    {GetName(PERSONS, person)}
                </div>}
            </div>
            {userType === "detail" && <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                height: '100%', marginRight: '10px'
            }}>
                <AppButton onClick={() => setUserType("common")}>
                    К сводной
                </AppButton>
            </div>}
        </div>
        {loading ?<AppModal modalVisible={loading} setModalVisible={setLoading} showLoading={true} canCloseOnBackdrop={false}/>:(
            type === "common" ?
            <div className={styles.main_div}>
                {/*<h1>Статистика</h1>*/}
            {/*<div style={{maxWidth: "600px"}}>{error ? error : data}</div>*/}
            <AppCircularProgress onNext={onNext} data={data}
                                 header={GetHeader({section, period, person})}
                                 centerBeginText={`${progress}%`}/>
            <AppWhiteBlock className={styles.white_block}>
                <div className={styles.info_list}>
                    <h4>Сводная статистика</h4>
                    <StatisticRow text={"Всего вопросов"} meaning={amount}/>
                    <StatisticRow text={"Усвоено материала"} meaning={`${progress}%`}/>
                    <StatisticRow text={"Всего ответов"} meaning={totalAttempts}/>
                    <StatisticRow text={"Всего верных"} meaning={totalRight}/>
                    <StatisticRow text={"Пройдено вопросов"} meaning={right}/>
                    <StatisticRow text={"Пройдено в %"} meaning={`${linearProgress}%`}/>
                </div>
                <AppButton onClick={() => {
                    //history.push(location.pathname, {type: "detail"})
                    setUserType("detail")
                }}>Подробно</AppButton>
            </AppWhiteBlock>
            </div> : <div className={styles.column_div}>
                    <div className={styles.main_div}>
                <AppWhiteBlock className={styles.white_block_with_border} style={{width: '100%'}}>
                    <h4>Статистика по разделам</h4>
                        <div className={styles.grid_div}>
                    {tags.filter(elem => elem.tag in TAGS).map((elem, i) => {
                        let name = TAGS[elem.tag].name
                        return <AppCircularProgress data={[{percentage: elem.score, name}]}
                                                    header={name}
                                                    fontSize={'14px'}
                                                    minHeight={'120px'}
                                                    beginRadius={42}
                                                    strokeWidth={15}
                                                    animatedText={false}
                                                    key={i.toString()}
                                                    centerBeginText={`${elem.score}%`}/>
                    })}
                </div>
                </AppWhiteBlock>
                    <AppWhiteBlock className={styles.white_block}>
                        <div className={styles.info_list}>
                            <h4>Сводная статистика</h4>
                            <StatisticRow text={"Всего вопросов"} meaning={amount}/>
                            <StatisticRow text={"Усвоено материала"} meaning={`${progress}%`}/>
                            <StatisticRow text={"Всего ответов"} meaning={totalAttempts}/>
                            <StatisticRow text={"Всего верных"} meaning={totalRight}/>
                            <StatisticRow text={"Пройдено вопросов"} meaning={right}/>
                            <StatisticRow text={"Пройдено в %"} meaning={`${linearProgress}%`}/>
                        </div>
                        {/*{userType === "detail" && <AppButton onClick={() => {*/}
                        {/*    //history.push(location.pathname, {type: "detail"})*/}
                        {/*    //console.log("CLICK", userType)*/}
                        {/*    setUserType("common")*/}
                        {/*}}>Назад к сводной</AppButton>}*/}
                    </AppWhiteBlock>
                </div>
                <div className={styles.main_div}>
                    <AppWhiteBlock style={{maxWidth: "90vw", width: '90%'}}>
                        <h4>Самые трудные вопросы</h4>
                    {questions.map((elem, i) => (
                        <div
                            className={styles.div_question}
                            onClick={() => {
                                setQuestionId(i);
                                setQuestionModal(true)
                            }}
                             key={i.toString()}>
                            <div className={styles.overflow_div}>
                                <div className={styles.overflow_single}>
                                    {elem.question}
                                </div>
                            </div>
                            <div className={styles.question_number_right}>
                                {elem.successful_attempts}
                            </div>
                            <div className={styles.question_number_wrong}>
                                {elem.attempts - elem.successful_attempts}
                            </div>
                        </div>
                    ))}
                    </AppWhiteBlock>
                </div>
                </div>
        )}
    </div>
}

export default StudentStatisticsPage;
