import React, {useState, useEffect} from 'react';
import AppButton from "../../components/AppButton";
import RequestWrapper from "../../helpers/RequestWrapper";
import styles from "./styles.module.scss";

import {THEME} from "../../constants/THEME";
import {AppActivityIndicator} from "../../components/AppActivityIndicator";
import cn from 'classnames';

const GOLD = "#ffce2a"
const SILVER = "#c4c4c4"
const BRONZE = "#d66800"

const BestPrev = (elem, index) => {
    const color = index === 1 ? GOLD : index === 2 ? SILVER : BRONZE;
    const marginTop = index === 1 ? 0 : 20;
    let name = elem.u.split(' ')
    return <div className={styles.bestThreeElem} style={{marginTop}}>
        <div className={styles.bestThreeHead} style={{backgroundColor: color}}>
            <div className={styles.bestThreePlace}>
                {`${index} МЕСТО`}
            </div>
        </div>
        {name.map((n, i) =>
        <div key={i.toString()} className={cn(styles.bestThreeName, styles.overflow_single)}>
            {n}
        </div>
        )}
        <div className={styles.bestThreeValue}>
            {elem.r}
        </div>
    </div>
}


const TableElem = (place, user, value, index, isMe) => {
    const backgroundColor = isMe ? THEME.ORANGE_300 : 'rgba(0, 0, 0, 0)'
    const color = index === 1 ? GOLD : index === 2 ? SILVER : index === 3 ? BRONZE :
        index === 0 ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0)';
    return <div key={index.toString()} className={styles.tableElemView} style={{backgroundColor}}>
        <div className={styles.place} style={{backgroundColor: color}}>
            <div className={styles.tableElemText}>{place}</div>
        </div>
        <div className={styles.user}>
            <div className={cn(styles.tableElemText, styles.overflow_single)}
                 style={{textAlign: 'left'}}>{user}</div>
        </div>
        <div className={styles.value}>
            <div className={styles.tableElemText}>
                {value}
            </div>
        </div>
    </div>
}


function RatingPage({}) {

    const [prev, setPrev] = useState([])
    const [data, setData] = useState([])
    const [user, setUser] = useState({})
    const [time, setTime] = useState({d: 4, h: 22})
    const [loading, setLoading] = useState(true)

    const {REQUEST} = RequestWrapper()

    const GetData = () => {
        REQUEST.GET({request: "statistics/rating/?action=get"}).then(res => {
            setData(res.json.data)
            setUser(res.json.user)
            // console.log("USER RATING", res.json.user)
            setTime(res.json.day)
            setPrev(res.json.previous)
            setTimeout(() => setLoading(false), 500)
        })
    }

    useEffect(() => {
        GetData()
    }, [])

    const MIN_DATA_LENGTH = 7;

    return (
        <>
            <div
                className={styles.absolute_fill}
            />
        <div className={styles.screen}
             //style={{backgroundColor: "#fff"}}
        >
            {/*<div*/}
            {/*    className={styles.absolute_fill}*/}
            {/*/>*/}
            <div style={{
                zIndex: 10,
                //position: 'absolute',
                top: 30, left: 0, right: 0, width: '100%'}}>
                <div className={styles.title}>
                    РЕЙТИНГ
                </div>
            </div>
            {loading ? <AppActivityIndicator/> :
                <div className={styles.mainScrollView}>
                    {
                        prev && prev.length >= 3 &&
                        <div className={styles.prevWeekView}>
                            <div className={styles.subtitle}>
                                ПОБЕДИТЕЛИ ПРОШЛОЙ НЕДЕЛИ
                            </div>
                            <div className={styles.bestThreeView}>
                                {BestPrev(prev[1], 2)}
                                {BestPrev(prev[0], 1)}
                                {BestPrev(prev[2], 3)}
                            </div>
                        </div>
                    }
                    <div className={styles.newWeekView}>
                        <div className={styles.newWeekTitleView}>
                            <div className={styles.subtitle}>
                                ЕЖЕНЕДЕЛЬНЫЙ ТУРНИР
                            </div>
                            <div className={styles.newWeekTimeLeft}>
                                ОСТАЛОСЬ: {time.d}Д. {time.h}Ч
                            </div>
                        </div>
                        <div className={styles.ratingView}>
                            {TableElem("МЕСТО", "ПОЛЬЗОВАТЕЛЬ", "ОЧКИ", 0)}
                            {
                                data.length >= MIN_DATA_LENGTH ? data.map((elem, i) =>
                                        TableElem(i + 1, elem.u, elem.r, i + 1, i + 1 === user.place)) :
                                    <div
                                        className={styles.bestThreeName}
                                        style={{color: "#000000", marginTop: 20}}>
                                        Данные по рейтингу скоро появятся
                                    </div>
                            }
                            {
                                data.length >= MIN_DATA_LENGTH && user.place > 0 && user.place > data.length &&
                                TableElem(user.place, user.u, user.r, user.place, true)
                            }
                            {
                                data.length >= MIN_DATA_LENGTH &&
                                (user.place <= 0 || user.place === undefined) && <div
                                    className={styles.bestThreeName}
                                    style={{color: "#000000", marginTop: 20}}>
                                    Чтобы попасть в рейтинг, необходимо проходить тесты!
                                </div>
                            }
                        </div>
                        {/*<div style={{height: 20}}/>*/}
                    </div>
                </div>}
        </div>
            </>
    );
}

export default RatingPage;
