import React, {useEffect, useState} from 'react';
import RequestWrapper from "../../../helpers/RequestWrapper";
import {AppModal} from "../../../components/AppModal";
import AdminProfilePage from "../AdminProfilePage";
import StudentProfilePage from "../StudentProfilePage";


function ProfilePage() {

    const {REQUEST} = RequestWrapper()

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onGetInfo()
    }, [])
    
    const onExit = () => {
        REQUEST.CREATED.EXIT()
    }

    const ErrorPage = () => (
        <div>
            Ошибка. Перезагрузите страничку.
        </div>
    )

    const ShowPage = () => {
        setTimeout(() => setLoading(false), 300);
    }

    const [page, setPage] = useState(ErrorPage)

    const onGetInfo = () => {
        REQUEST.CREATED.GET_USER_INFO().then(res => {
            try {
                console.log("Profile user info", res.json)
                if (res.json.is_staff) {
                    setPage((props) => AdminProfilePage({...props, userData: res.json}))
                } else {
                    setPage(StudentProfilePage)
                }
                ShowPage()
            } catch (e) {
                setPage(ErrorPage)
                console.log("ERR", e);
                ShowPage();
            }
        })
    };

    // const onTest = () => {
    //     return Promise.resolve(5 + 5)
    // }
    //
    // useEffect(() => {
    //     onTest().then(res => console.log("FIVE", res))
    // })

    return <div>
        {
            loading ? <AppModal modalVisible={loading} setModalVisible={setLoading} showLoading={true} canCloseOnBackdrop={false}/> :
                page
        }
    </div>
}

export default ProfilePage;
