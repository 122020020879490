import React from "react";
import styles from './styles.module.scss';

import cn from 'classnames';

const AppButton = (
    {
        children,
        onClick = () => {},
        style = {},
        type = "orange",
        // height_ = "40px",
        // width_ = "200px",
        ...props
    }) => {

    return <button
        className={cn(styles.button,
            type === "red" && styles.button_red,
            type === "orange" && styles.button_orange)}
        onClick={onClick}
        style={{...style}}
        {...props}
    >
        {children}
    </button>;

};


export default AppButton;
