import React, {useEffect, useRef, useState} from 'react';
import styles from "./../styles.module.scss";
import AdminStatisticsPage, {
    GetTime,
    METRICS_NAMES,
    SimpleGraphDataRequest,
    statMeanBigLabel,
    statMeanLabel
} from "../index";
import AppGraph from "../../../components/AppGraph";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {useSelector} from "react-redux";
import {DB_PATH, METRICS} from "../../../constants";
import AppSelectedList from "../../../components/AppSelectedList";
import AppButton from "../../../components/AppButton";
import cn from 'classnames';
import {ChooseDiv} from "./activity";
import {AppActivityIndicator} from "../../../components/AppActivityIndicator";


export const ProductRatingDataRequest = ({R, object, category, value, bestAmount, listSide, setD, time, setLoading}) => {
    const topStr = `&top=${bestAmount}`;
    setLoading(true)
    R.GET({request: DB_PATH.STATISTICS.METRIC.N +
            `?metric=product_rating&object=${object}&category=${category}&value=${value}&best=${listSide}&time=${time}${topStr}`,
        meta: {ms: 300000}}).then(res => {
        try {
            setD(res.json.data)
            setLoading(false)
        } catch (e) {
            console.log(e)
            // setError("Error: " + e)
        }
    })
}

const Objects = [
    {id: 0, key: "0", name: "Теория", value: 'theory'},
    {id: 1, key: "1", name: "Тесты", value: 'tests'}
]

const Categories = [
    {id: 0, key: "0", name: "Все категории", value: 'all'},
    {id: 1, key: "1", name: "Древность", value: 'anc'},
    {id: 2, key: "2", name: "Новое время", value: 'ren'},
    {id: 3, key: "3", name: "Новейшее время", value: 'mod'},
]

const Values = [
    {id: 0, key: "0", name: "Количество использований", value: 'total_usages'},
    {id: 1, key: "1", name: "Среднее время прохождения", value: 'average_time', isTime: true},
    {id: 2, key: "2", name: "Суммарное время прохождения", value: 'total_time', isTime: true},
    {id: 3, key: "3", name: "Среднее по блокам", value: 'average_completed_blocks'},
    {id: 4, key: "4", name: "Суммарное по блокам", value: 'completed_blocks'},
    {id: 5, key: "5", name: "Средний прогресс, %", value: 'average_progress'},
]

const BestAmount = [
    {id: 0, key: "0", name: "ТОП-3", value: 3},
    {id: 1, key: "1", name: "ТОП-5", value: 5},
    {id: 2, key: "2", name: "ТОП-10", value: 10},
    {id: 3, key: "3", name: "ТОП-20", value: 20},
]

const ListSide = [ // Формировать топ по показателям
    {id: 0, key: "0", name: "Лучших продуктов", value: "True"},
    {id: 1, key: "1", name: "Худших продуктов", value: "False"},
]

// const WithUsers = [
//     {id: 0, key: "0", name: "Без сравнения", value: ''},
//     {id: 1, key: "1", name: "Уникальные пользователи", value: 'unique_users'},
//     {id: 2, key: "2", name: "Количество использований", value: 'total_usages'},
// ]

// export const ChooseDiv = ({list, active, setActive, name, working=true}) => {
//     return <div className={styles.activity_div}>
//         <div className={styles.head_div}>{name}</div>
//         <div className={styles.activity_inner_div}>
//             {list.map((elem, i) => working ? <div className={cn(
//                 styles.activity_inner_elem,
//                 active === i && styles.activity_inner_elem_active
//                 )} onClick={() => setActive(i)} key={elem.key}>
//                     {elem.name}
//                 </div> :
//                 <div className={cn(
//                     styles.activity_inner_elem,
//                     styles.activity_inner_elem_disable
//                 )} key={elem.key}>
//                     {elem.name}
//                 </div>
//             )}
//         </div>
//     </div>
// }


const GetCompletedTime = (time) => {
    const s = Math.floor(time % 60).toString()
    const sec = s.length === 1 ? "0" + s : s
    const m = Math.floor((time % 3600) / 60).toString()
    const min = m.length === 1 ? "0" + m : m;
    if (Math.floor(time / 3600) > 0) {
        return `${Math.floor(time / 3600)}:${min}:${sec}`
    }
    return `${min}:${sec}`
}

const StatisticProductsRatingPage = ({history}) => {

    const [data, setData] = useState([])
    const {REQUEST} = RequestWrapper();

    const label = "Доля";

    const time = useSelector(state => state.statistics.time);

    const clearData = () => {
        const clearAll = () => {
            setData([]);
        }
        return Promise.resolve(clearAll())
    }

    const [loading, setLoading] = useState(false);

    const [object, setObject] = useState(1) // ['theory', 'tests']
    const [category, setCategory] = useState(0) // ['rus', 'world', 'terms']
    const [value, setValue] = useState(0) // ['time', 'unique_users', 'total_usages', 'cards_amount']
    const [bestAmount, setBestAmount] = useState(2) // unsigned int
    const [listSide, setListSide] = useState(0) // ['True', 'False']

    const CalculateMetric = () => {
        setValueColumn(value)
        clearData().then(res => {
            ProductRatingDataRequest({
                R: REQUEST, setD: setData, time,
                setLoading,
                object: Objects[object].value,
                category: Categories[category].value,
                value: Values[value].value,
                bestAmount: BestAmount[bestAmount].value,
                listSide: ListSide[listSide].value
            });
        });
    }

    const [valueColumn, setValueColumn] = useState(value)

    useEffect(() => {
        CalculateMetric()
    }, [time])

    useEffect(() => {

    }, [])

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }


    return <AdminStatisticsPage history={history} time={time} screenId={3}>
        <div className={styles.graphics_container}>
            <div className={styles.conversion_settings_div}>
                {ChooseDiv({list: Objects, active: object, setActive: setObject,
                    name: "Данные для анализа"})}
                {ChooseDiv({list: Categories, active: category, setActive: setCategory,
                    name: "Категория данных"})}
                {ChooseDiv({list: Values, active: value, setActive: setValue,
                    name: "Параметр отображения"})}
                {ChooseDiv({list: BestAmount, active: bestAmount, setActive: setBestAmount,
                    name: "Количество продуктов в списке",})}
                {ChooseDiv({list: ListSide, active: listSide, setActive: setListSide,
                    name: "Использовать вывод со стороны"})}

                <div style={{marginTop: "20px"}}>
                    <AppButton onClick={() => {
                        CalculateMetric();
                        scrollToBottom();
                    }}>Посчитать метрику</AppButton>
                </div>
            </div>

            <div className={styles.graphic_elem} ref={messagesEndRef}>
                {/*{JSON.stringify(data)}*/}
                {
                    loading ? <div className={styles.div_loading}>
                        <AppActivityIndicator type="large"/>
                    </div> : <div className={styles.rating_products_div}>
                        <div className={styles.rating_elem_div}
                             style={{borderBottomStyle: "solid"}}
                        >
                            <div className={cn(styles.rating_number, styles.rating_text)}
                                 style={{fontSize: "16px", color: "black", backgroundColor: 'rgba(0, 0, 0, 0)'}}
                            >
                                Номер
                            </div>
                            <div className={cn(styles.rating_name, styles.rating_text)}
                                 style={{fontSize: "16px"}}
                            >
                                {`Название продукта из категории "${Categories[category].name}"`}
                            </div>
                            <div className={cn(styles.rating_value, styles.rating_text)}
                                 style={{fontSize: "16px", textAlign: "center"}}
                            >
                                {Values[valueColumn].name}
                            </div>
                        </div>
                        {data.map((elem, i) => <div className={styles.rating_elem_div} key={i.toString()}>
                            <div className={cn(styles.rating_number, styles.rating_text)}>
                                {i + 1}
                            </div>
                            <div className={cn(styles.rating_name, styles.rating_text)}>
                                {elem.name}
                            </div>
                            <div className={cn(styles.rating_value, styles.rating_text)}>
                                {Values[valueColumn].isTime ?
                                    GetCompletedTime(elem.value)
                                    : elem.value}
                            </div>
                        </div>)}
                    </div>
                }

            </div>
        </div>
    </AdminStatisticsPage>
}

export default StatisticProductsRatingPage;