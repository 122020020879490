import {SET_TIME, SET_MENU_ELEMENT_ID} from "../types";

const initialState = {
    activeElemId: 0,
    time: 7,
    loading: false,
    error: false,
};

export const StatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIME:
            return {
                ...state,
                time: action.time,
                loading: false,
                error: false,
            };
        case SET_MENU_ELEMENT_ID:
            return {
                ...state,
                activeElemId: action.activeElemId,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};
