import React, { useState } from "react";
import styles from "./styles.module.scss";

export const ProgressLine = (
    {
        done,
        all = 100,
        height = 30,
        borderRadius_ = 9,
    }) => {

    const styleParameter = {
        height: height,
        borderRadius: borderRadius_,
    };
    let style = {
        opacity: 1,
        width: `${done}%`,
        height: height,
        borderRadius: borderRadius_,
        borderBottomStartRadius: borderRadius_,
        borderTopStartRadius: borderRadius_,
    };
    let textProgress = `${done}%`;
    if (all !== 100) {
        textProgress = `${done}/${all}`;
        const percent = (100 / all) * done;
        style = {
            borderRadius: borderRadius_,
            opacity: 1,
            borderBottomStartRadius: borderRadius_,
            borderTopStartRadius: borderRadius_,
            width: `${percent}%`,
            height: height,
        };
    }

    if (done === all) {
        style = {
            borderRadius: borderRadius_,
            borderBottomStartRadius: borderRadius_,
            borderTopStartRadius: borderRadius_,
            ...style,
        };
    }
    return (
        <div className={styles.progress} style={{ ...styleParameter }}>
            <div className={styles.text}>
                {textProgress}
            </div>
            <div className={styles.progressDone} style={{ ...style }}/>
        </div>
    );
};