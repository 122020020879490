import React from 'react';
// import styles from "./styles.module.scss";


function CompetitionPage() {

    // function Right (index) {
    //     document.getElementById(index.toString()).classList.add(styles.right);
    //     document.getElementById(index.toString()).classList.remove(styles.wrong)
    // }
    //
    // function Wrong (index) {
    //     document.getElementById(index.toString()).classList.remove(styles.right);
    //     document.getElementById(index.toString()).classList.add(styles.wrong)
    // }

    return <div>
        <h1>СОРЕВНОВАНИЯ</h1>
    </div>
}

export default CompetitionPage;
