import React, {useEffect, useState} from 'react';
import AppButton from "../../../components/AppButton";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {MAIN_URL, ROLES, SECTIONS} from "../../../constants";
import {withRouter} from "react-router-dom";
import cn from 'classnames';
import styles from "./styles.module.scss";
import AppInput from "../../../components/AppInput";
import {AppWhiteBlock} from "../../../components/AppWhiteBlock";
import {CardAchievement} from "../../../components/UI/CardAchievement";
import {AppActivityIndicator} from "../../../components/AppActivityIndicator";

const roles = [
    {key: 0, name: ROLES.ADMIN,},
    {key: 1, name: ROLES.STUDENT},
    {key: 2, name: ROLES.TEACHER}
]

function compareAchievements( a, b ) {
    if ( a.v < b.v ) {
        return -1;
    }
    if ( a.v > b.v ) {
        return 1;
    }
    return 0;
}

const reducerSum = (acc, current) => acc + (current.is_open ? 1 : 0);

function AdminProfilePage({history, userData}) {

    const {REQUEST} = RequestWrapper()

    const [activeRole, setActiveRole] = useState(roles.findIndex(el => el.name === REQUEST.VALUES.ROLE));

    const [name, setName] = useState("who?")
    const [nickname, setNickname] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")

    // const [loading, setLoading] = useState(true);

    const onExit = () => {
        REQUEST.CREATED.EXIT()
    }

    const RoleElem = (role) => {
        return <div onClick={() => {
            REQUEST.CREATED.SET_ADMIN_ROLE(role.name);
            setActiveRole(role.key)
        }}
                    key={role.key.toString()}
                    className={cn(styles.role_elem, role.key === activeRole && styles.role_active)}
        >
            {role.name}
        </div>
    }

    const toUsualProfile = () => {
        history.push(MAIN_URL.PROFILE);
    }

    const DataDict = {
        first_name: {
            value: firstName,
            setValue: setFirstName,
        },
        last_name: {
            value: lastName,
            setValue: setLastName
        },
        nickname: {
            value: nickname,
            setValue: setNickname,
        },
        email: {
            value: email,
            setValue: setEmail
        }
    }

    const SetUserData = (data) => {
        setFirstName(data.first_name || "")
        setLastName(data.last_name || "")
        setNickname(data.nickname || "")
        setEmail(data.username || "")
    }

    useEffect(() => {
        SetUserData(userData)
    }, [])

    const onGetInfo = () => {
        REQUEST.CREATED.GET_USER_INFO().then(res => {
            try {
                console.log("AdminProfile user info:", res.json)
                if (!res.json.is_staff) {
                    toUsualProfile();
                    return;
                }
                setName(JSON.stringify(res.json))
                //SetUserData(res.json)

                // setLoading(false)
            } catch (e) {
                console.log("ERR", e);
            }
        })
    };

    const [achievements, setAchievements] = useState([])
    const [achLoading, setAchLoading] = useState(true)

    useEffect(() => {
        REQUEST.GET({request: "achievements/"}).then(res => {
            let ans = []
            for (let key in res.json) {
                let mass = res.json[key]
                mass.sort(compareAchievements)
                let elem = {
                    name: key,
                    all: mass.length,
                    done: mass.reduce(reducerSum, 0)
                }
                ans.push(elem)
            }
            setAchievements(ans)
            setAchLoading(false)
            // console.log(res.json)
        })
    }, [])

    const [statistics, setStatistics] = useState([])
    const [statLoading, setStatLoading] = useState(true)

    // const NamesSection = {
    //     anc: "Древность",
    //     ren: "Новое время",
    //     mod: "Новейшее время"
    // }

    useEffect(() => {
        REQUEST.GET({request: "statistics/"}).then(res => {
            let ans = []
            for (let key in res.json) {
                let elem = {
                    name: SECTIONS[key].name,
                    all: 100,
                    onClick: () => history.push(MAIN_URL.STATISTICS + '/' + key),
                    done: res.json[key]
                }
                ans.push(elem)
            }
            setStatistics(ans)
            setStatLoading(false)
            // console.log(res.json)
        })
    }, [])


    return <div className={styles.screen}>
            <div className={styles.div_row}>
                <AppWhiteBlock className={styles.div_user_data}
                               style={{
                                   justifyContent: "flex-start",
                                   paddingLeft: "30px",
                                   paddingRight: "30px",
                               }}
                >
                    <div className={styles.head_text}>Профиль</div>
                    <div className={styles.input_div}>
                        <AppInput value={firstName} setValue={setFirstName} label="Имя"
                                  editable={false}/>
                    </div>
                    <div className={styles.input_div}>
                        <AppInput value={lastName} setValue={setLastName} label="Фамилия" editable={false}/>
                    </div>
                    <div className={styles.input_div}>
                        <AppInput value={nickname} setValue={setNickname} label="Никнейм" editable={false}/>
                    </div>
                    <div className={styles.input_div}>
                        <AppInput value={email} setValue={setEmail} label="Логин" editable={false}/>
                    </div>
                </AppWhiteBlock>
                <div className={styles.div_column}>
                    <AppWhiteBlock className={styles.white_block} style={{marginBottom: "10px"}}>
                        <div className={styles.head_text}>Статистика</div>
                        <div className={styles.achievements_list}>
                            {statLoading ? <AppActivityIndicator type="small"/> :
                                statistics.map((ach, i) => CardAchievement({...ach, i}))}
                        </div>
                    </AppWhiteBlock>
                    <AppWhiteBlock className={styles.white_block}>
                        <div className={styles.head_text}>Достижения</div>
                        <div className={styles.achievements_list}>
                        {achLoading ? <AppActivityIndicator type="small"/> :
                            achievements.map((ach, i) => CardAchievement({...ach, i}))}
                        </div>
                    </AppWhiteBlock>
                </div>
            </div>
        <div className={styles.div_row_settings}>
        <AppWhiteBlock className={styles.div_settings}>
            <div className={styles.inner_div_settings}>
            <div className={styles.roles_list}>
                <div className={styles.head_text}>Роль</div>
                {roles.map(elem => RoleElem(elem))}
            </div>
            <AppButton onClick={onExit} type="red">
                Выйти
            </AppButton>
            </div>
        </AppWhiteBlock>
        </div>
    </div>
}

export default withRouter(AdminProfilePage);
