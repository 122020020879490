import {SET_MENU_ELEMENT_ID, SET_TIME} from "../types";


export const setTime = (time) => {
    return async (dispatch) => {
        dispatch({
            type: SET_TIME,
            time
        });
    };
};

export const setActiveElementId = (id) => {
    return async (dispatch) => {
        dispatch({
            type: SET_MENU_ELEMENT_ID,
            activeElemId: id
        });
    };
};