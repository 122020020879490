import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import {THEME} from "../../../constants/THEME";

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

const AppCircularProgress = (
    {
        //sqSize = 200,
        //percentage = 0,
        strokeWidth = 20,
        data = [{id: 0, percentage: 20, name: "Древность"},
            {id: 1, percentage: 70, name: "Новое время"},
            {id: 2, percentage: 55, name: "Новейшее время"}],
        hasHeader = true,
        header = "Прогресс",
        centerBeginText = "",
        minHeight = "370px",
        beginRadius = 60,
        fontSize = "24px",
        animatedText = true,
        onNext = () => {},
    }) => {

    // const sqSize = this.props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    //const radius = (sqSize - strokeWidth) / 2;
    //const beginRadius = 60;
    const indent = 3;
    const oneBlockAmount = 5;
    const [blocksAmount, setBlocksAmount] = useState(Math.round(data.length / oneBlockAmount))
    const [currentBlock, setCurrentBlock] = useState(0)

    const [activeHover, setActiveHover] = useState(-1)
    const [firstTime, setFirstTime] = useState(true)

    const [circleData, setCircleData] = useState([])

    useEffect(() => {
        setCircleData(data.filter((elem, i) =>
            i >= currentBlock * oneBlockAmount && i < (currentBlock + 1) * oneBlockAmount).map((elem, i) => {
            const r = beginRadius + i * (indent + strokeWidth);
            const dA = r * Math.PI * 2;
            return {
                ...elem,
                radius: r,
                dashArray: dA,
                fO: dA - dA * (elem.percentage ? elem.percentage : 1) / 100,
                dashOffset: firstTime ? dA : dA - dA * (elem.percentage ? elem.percentage : 1) / 100
            }}))
    }, [currentBlock, oneBlockAmount, firstTime, data])

    useEffect(() => {
        //console.log("CIRCLE DATA", data)
        setTimeout(() => {
            setCircleData(c => c.map(elem => ({...elem, dashOffset: elem.fO})))
            setFirstTime(false)
        }, 100)
    }, [])

    const onHover = (id) => {
        setActiveHover(id)
    }
    const [headerOpacity, setHeaderOpacity] = useState('rgba(0, 0, 0, 1)')
    const [headerText, setHeaderText] = useState(header)
    const [centerText, setCenterText] = useState(centerBeginText)

    useEffect(() => {
        if (animatedText) {
            setHeaderOpacity('rgba(0, 0, 0, 0)')
            // setHeaderOpacity('rgba(0, 0, 0, 1)')
            setTimeout(() => {
                setHeaderOpacity('rgba(0, 0, 0, 1)');
                setHeaderText(activeHover >= 0 ? circleData[activeHover].name : header)
                setCenterText(activeHover >= 0 ? `${circleData[activeHover].percentage}%` : centerBeginText)
            }, 300)
        }
    }, [activeHover])

    return (
        <div style={{
            height: '100%', width: '100%',
            //backgroundColor: "red",
            minHeight,
            display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexDirection: 'column'}}
        >
            {hasHeader && <div className={styles.header}
                               style={{color: headerOpacity, fontSize}}
            >
                {headerText}
            </div>}
        <svg
            x="50%"
            y="50%"
            //viewBox={viewBox}
            className={cn(styles.svg_style, activeHover >= 0 && styles.svg_on_focus)}
            style={{minHeight,
                //backgroundColor: "red"
            }}
            >
            <g
                //transform={`rotate(0.25turn)`}
            >
                {circleData.map((elem, i) => (
                    <circle
                        key={i.toString()}
                        onClick={() => onNext(currentBlock * oneBlockAmount + i)}
                        className={cn(styles.circle_background,
                            activeHover === i && styles.circle_active
                        )}
                        cx="50%"
                        cy="50%"
                        onMouseEnter={() => onHover(i)}
                        onMouseLeave={() => onHover(-1)}
                        // onFocus={() => onHover(i)}
                        // onBlur={() => onHover(-1)}
                        r={elem.radius}
                        strokeWidth={`${strokeWidth + indent}px`}
                    >
                        {/*<text*/}
                        {/*    //x="50%"*/}
                        {/*    //y="50%"*/}
                        {/*    dy=".3em"*/}
                        {/*    fill="#0f0"*/}
                        {/*    className={styles.circle_text}*/}
                        {/*    textAnchor="middle"*/}
                        {/*    >*/}
                        {/*    elem.id}*/}
                        {/*</text>*/}
                    </circle>))}
                {circleData.map((elem, i) => (
                    <circle
                        key={i.toString()}
                        className={cn(styles.circle_progress,
                            activeHover >= 0 && activeHover !== i && styles.inactive,
                            activeHover === i && styles.active
                        )}
                        cx="50%"
                        cy="50%"
                        focusable={true}
                        onClick={() => onNext(currentBlock * oneBlockAmount + i)}
                        onMouseEnter={() => onHover(i)}
                        onMouseLeave={() => onHover(-1)}
                        onFocus={() => onHover(i)}
                        onBlur={() => onHover(i)}
                        r={elem.radius}
                        strokeWidth={`${strokeWidth}px`}
                        style={{
                            strokeDasharray: elem.dashArray,
                            strokeDashoffset: elem.dashOffset
                        }}
                    />))}
            </g>
            <text
                className={styles.circle_text}
                style={{color: headerOpacity}}
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle">
                {centerText}
            </text>
        </svg>
            {/*<div*/}
            {/*    //style={{position: 'absolute'}}*/}
            {/*>GGG</div>*/}
            {blocksAmount > 1 && <div className={styles.div_numbers_array}>
                {numbers.filter(num => num < blocksAmount).map((num, i) => {
                    return <div
                        className={cn(styles.div_number,
                            num === currentBlock && styles.div_number_active)}
                        onClick={() => setCurrentBlock(i)}
                    >
                        {num + 1}
                    </div>
                })}
            </div>}
        </div>
    );
}


export default AppCircularProgress