import React, {useEffect, useRef, useState} from 'react';
import styles from "./../styles.module.scss";
import AdminStatisticsPage, {
    GetTime,
    METRICS_NAMES,
    SimpleGraphDataRequest,
    statMeanBigLabel,
    statMeanLabel
} from "../index";
import AppGraph from "../../../components/AppGraph";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {useSelector} from "react-redux";
import {DB_PATH, METRICS} from "../../../constants";
import AppSelectedList from "../../../components/AppSelectedList";
import AppButton from "../../../components/AppButton";
import cn from 'classnames';


export const ActivityDataRequest =
    ({R, object, category, value, border, setD, label, time, withUsers=false}) => {
    const withUsersStr = withUsers ? `&with_users=${withUsers}` : "";
    R.GET({request: DB_PATH.STATISTICS.METRIC.N +
            `?metric=activity&object=${object}&category=${category}&value=${value}&border=${border}&time=${time}${withUsersStr}`,
        meta: {ms: 300000}}).then(res => {
        try {
            const xAxes = res.json.data.map(elem => GetTime({userDate: elem.d}))
            withUsers ?
            setD([
                {
                    label: value,
                    secondaryAxisID: 'first',
                    bigLabel: `${object} & ${category}`,
                    config: {type: "gray"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.v}))
                },
                {
                    label: withUsers,
                    secondaryAxisID: 'second',
                    bigLabel: withUsers,
                    config: {color: "#FA0000"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.u}))
                },
                {
                    label: statMeanLabel,
                    bigLabel: statMeanBigLabel,
                    config: {type: "median"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.m}))
                }
            ]) :
                setD([
                    {
                        label: value,
                        secondaryAxisID: 'first',
                        bigLabel: `${object} & ${category}`,
                        config: {type: "gray"},
                        data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.v}))
                    },
                    {
                        label: statMeanLabel,
                        bigLabel: statMeanBigLabel,
                        config: {type: "median"},
                        data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.m}))
                    }
                ])
        } catch (e) {
            console.log(e)
            // setError("Error: " + e)
        }
    })
}

const Objects = [
    {id: 0, key: "0", name: "Карточки", value: 'cards'},
    {id: 1, key: "1", name: "Списки", value: 'list'}
]

const Categories = [
    {id: 0, key: "0", name: "Даты истории России", value: 'rus'},
    {id: 1, key: "1", name: "Даты истории мира", value: 'world'},
    {id: 2, key: "2", name: "Термины", value: 'terms'},
]

const Values = [
    {id: 0, key: "0", name: "Уникальные пользователи", value: 'unique_users'},
    {id: 1, key: "1", name: "Количество использований", value: 'total_usages'},
    {id: 2, key: "2", name: "Время сессии", value: 'time'},
    {id: 3, key: "3", name: "Количество карточек", value: 'cards_amount', onlyCards: true},
]

const BordersTime = [
    {id: 0, key: "0", name: "0 секунд", value: 0},
    {id: 1, key: "1", name: "5 секунд", value: 7},
    {id: 2, key: "2", name: "10 секунд", value: 10},
    {id: 3, key: "3", name: "30 секунд", value: 30},
]

const BordersCards = [
    {id: 0, key: "0", name: "0 карточек", value: 0},
    {id: 1, key: "1", name: "1 карточка", value: 1},
    {id: 2, key: "2", name: "3 карточки", value: 3},
    {id: 3, key: "3", name: "10 карточек", value: 10},
]

const WithUsers = [
    {id: 0, key: "0", name: "Без сравнения", value: ''},
    {id: 1, key: "1", name: "Уникальные пользователи", value: 'unique_users'},
    {id: 2, key: "2", name: "Количество использований", value: 'total_usages'},
]

export const ChooseDiv = ({list, active, setActive, name, working=true}) => {
    return <div className={styles.activity_div}>
        <div className={styles.head_div}>{name}</div>
        <div className={styles.activity_inner_div}>
            {list.map((elem, i) => working ? <div className={cn(
                styles.activity_inner_elem,
                active === i && styles.activity_inner_elem_active
            )} onClick={() => setActive(i)} key={elem.key}>
                {elem.name}
            </div> :
                <div className={cn(
                    styles.activity_inner_elem,
                    styles.activity_inner_elem_disable
                )} key={elem.key}>
                    {elem.name}
                </div>
            )}
        </div>
    </div>
}

const StatisticActivityPage = ({history}) => {

    const [data, setData] = useState([])
    const {REQUEST} = RequestWrapper();

    const label = "Доля";

    const time = useSelector(state => state.statistics.time);

    const clearData = () => {
        const clearAll = () => {
            setData([]);
        }
        return Promise.resolve(clearAll())
    }

    const [object, setObject] = useState(0) // ['list', 'cards']
    const [category, setCategory] = useState(0) // ['rus', 'world', 'terms']
    const [value, setValue] = useState(0) // ['time', 'unique_users', 'total_usages', 'cards_amount']
    const [border, setBorder] = useState(0) // unsigned int
    const [withUsers, setWithUsers] = useState(0) // ['', 'unique_users', 'total_usages']

    const CalculateMetric = () => {
        clearData().then(res => {
            const borderAmount = Values[value].value === 'time' ? BordersTime[border].value :
                (Values[value].value === 'cards_amount' ? BordersCards[border].value : 0);
            ActivityDataRequest({
                R: REQUEST, setD: setData, time,
                object: Objects[object].value,
                category: Categories[category].value,
                value: Values[value].value,
                border: borderAmount,
                withUsers: WithUsers[withUsers].value,
                label: "smth"
                });
        });
    }

    useEffect(() => {
        CalculateMetric()
    }, [time])

    useEffect(() => {

    }, [])

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const [datumFormat, setDatumFormat] = useState((x) => x)
    const datumFormatRef = useRef(datumFormat);

    useEffect(() => {
        setDatumFormat(Values[value].value === 'time' ?
            (x) => `${Math.floor(x / 60)}m ${x % 60}s` : x => x)
    }, [value])

    const GetDatumFormat = () => {
        if (Values[value].value === 'time') {
            return (x) => `${Math.floor(x / 60)}m ${x % 60}s`
        }
        return x => x
    }

    return <AdminStatisticsPage history={history} time={time} screenId={2}>
        <div className={styles.graphics_container}>
            <div className={styles.conversion_settings_div}>
                {ChooseDiv({list: Objects, active: object, setActive: setObject,
                    name: "Данные для анализа"})}
                {ChooseDiv({list: Categories, active: category, setActive: setCategory,
                    name: "Категория данных"})}
                {ChooseDiv({list: Objects[object].value === 'cards' ? Values :
                        Values.filter(elem => !elem.onlyCards)
                    , active: value, setActive: setValue,
                    name: "Параметр отображения"})}
                {ChooseDiv({list:
                        Values[value].value === 'cards_amount' ? BordersCards : BordersTime,
                    active: border, setActive: setBorder,
                    name: "Граница рассмотрения",
                    working: Values[value].value === 'time' || Values[value].value === 'cards_amount'})}
                {ChooseDiv({list: WithUsers, active: withUsers, setActive: setWithUsers,
                    name: "Добавление данных о пользователях для сравнения",
                    working: Values[value].value === 'time' || Values[value].value === 'cards_amount'
                })}

                <div style={{marginTop: "20px"}}>
                    <AppButton onClick={() => {
                        CalculateMetric();
                        scrollToBottom();
                    }}>Посчитать метрику</AppButton>
                </div>
            </div>

            <div className={styles.graphic_elem} ref={messagesEndRef}>
                {Values[value].value === 'time' ? <AppGraph data={data}
                          name={"Активность"}
                          // datumFormat={datumFormatRef.current}
                          datumFormat={x => `${Math.floor(x / 60)}m ${x % 60}s`}
                          primaryAxisType="time"
                /> : <AppGraph data={data}
                               name={"Активность"}
                               // datumFormat={datumFormatRef.current}
                    // datumFormat={Values[value].value === 'time' ?
                    //         x => `${Math.floor(x / 60)}m ${x % 60}s` : x => x}
                               primaryAxisType="time"
                />
                }
            </div>
        </div>
    </AdminStatisticsPage>
}

export default StatisticActivityPage;