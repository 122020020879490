import React, {useEffect, useState} from 'react';
import styles from "./../styles.module.scss";
import AdminStatisticsPage, {GetTime, METRICS_NAMES, SimpleGraphDataRequest} from "../index";
import AppGraph, {MyChart} from "../../../components/AppGraph";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {useSelector} from "react-redux";
import AppSelectedList from "../../../components/AppSelectedList";
import AppButton from "../../../components/AppButton";
import {DB_PATH} from "../../../constants";

const TimeData = [
    {id: 0, key: "0", name: "За 3 дня", value: 3},
    {id: 1, key: "1", name: "За 5 дней", value: 5},
    {id: 2, key: "2", name: "За 7 дней", value: 7},
]

const EntersTypeData = [
    {id: 0, key: "0", name: "Уникальные пользователи", value: "unique_users"},
    {id: 1, key: "1", name: "Всего заходов", value: "entered_users"},
]

const ENTERS_METRICS = [
    {
        name: "unique_users_times",
        key: "0",
        label: "Пользователи",
        bigLabel: "Входы уникальных пользователей каждые 30 минут",
        short_label: "",
    },
    {
        name: "users_times",
        key: "1",
        label: "Пользователи",
        bigLabel: "Все входы пользователей каждые 30 минут",
        short_label: "",
    },
]

const GetEntersData = (REQUEST, metric, setD, time) => {
    REQUEST.GET({request: DB_PATH.STATISTICS.METRIC.N + `?metric=${metric.name}&time=${time}`,
        meta: {ms: 300000}}).then(res => {
        try {
            //console.log(res.json);
            // return;
            // const xAxes = res.json.dates
            setD([...res.json.dates.map((date, i) => ({
                label: date.substr(5),
                bigLabel: metric.bigLabel + ` (${date.substr(5)})`,
                config: {type: "gray"},
                data: res.json.data.map((el, j) => ({
                    x: GetTime({seconds: el.t}),
                    y: el.enters[i]
                }))
            })), {
                label: "Среднее",
                bigLabel: metric.bigLabel + " (среднее)",
                config: {type: "median", color: "#3354FF", median: true},
                data: res.json.data.map((el, j) => ({
                    x: GetTime({seconds: el.t}),
                    y: el.enters.reduce((sum, current) => sum + current, 0) / el.enters.length,
                }))
            }])
        } catch (e) {
            console.log(e)
        }
    })
}

const labelUsers = "Юзеры";
const labelProducts = "Продукты";

const StatisticUsersPage = ({history}) => {

    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])

    const [data5, setData5] = useState([])
    const [data6, setData6] = useState([])

    const [loading, setLoading] = useState(false);

    useEffect(() => {
       if (data1.length && data2.length && data3.length && data4.length) {
           setLoading(false)
       } else {
           setLoading(true)
       }
    }, [data1, data2, data3, data4])

    const [{ activeSeriesIndex, activeDatumIndex }, setState] = React.useState({
        activeSeriesIndex: -1,
        activeDatumIndex: -1
    });

    const [activePoint, setActivePoint] = useState(false);

    const {REQUEST} = RequestWrapper();

    const clearData = () => {
        const clearAll = () => {
            setData1([]);
            setData2([]);
            setData3([]);
            setData4([]);
        }
        return Promise.resolve(clearAll())
    }
    const time = useSelector(state => state.statistics.time);
    const [innerTime, setInnerTime] = useState(0)
    const [entersType, setEntersType] = useState(0)

    useEffect(() => {
        clearData().then(res => {
            SimpleGraphDataRequest(REQUEST, METRICS_NAMES[0], setData1, labelUsers, time);
            SimpleGraphDataRequest(REQUEST, METRICS_NAMES[1], setData2, labelUsers, time);
            SimpleGraphDataRequest(REQUEST, METRICS_NAMES[2], setData3, labelProducts, time);
            SimpleGraphDataRequest(REQUEST, METRICS_NAMES[3], setData4, labelProducts, time);
        });
    }, [time])

    const GetEntersGraph = () => {
        Promise.resolve(setData5([])).then(res => {
            GetEntersData(REQUEST, ENTERS_METRICS[entersType], setData5, TimeData[innerTime].value)
        })
    }

    useEffect(() => {
        // console.log("D5", data5)
        setData6(data5.map(elem => {
            let sum = 0;
            return {
            ...elem, data: elem.data.map(item => {
                sum += item.y;
                return {...item, y: sum}
            })
        }}))
    }, [data5])

    useEffect(() => {
        GetEntersGraph()
    }, [])

    return <AdminStatisticsPage history={history} time={time} screenId={0}>
    <div className={styles.graphics_container}>
        <div className={styles.graphics_line}>
            <div className={styles.graphic_elem}>
                <MyChart data={data1}
                         height={"200px"}
                         name={METRICS_NAMES[0].bigLabel}
                         loading={loading}
                         primaryAxisType="time"
                         activePoint={activePoint}
                         setActivePoint={setActivePoint}
                         setState={setState}
                         activeDatumIndex={activeDatumIndex}
                         activeSeriesIndex={activeSeriesIndex}
                />
            </div>
            <div className={styles.graphic_elem}>
                <MyChart data={data2}
                         height={"200px"}
                         name={METRICS_NAMES[1].bigLabel}
                         loading={loading}
                         primaryAxisType="time"
                         activePoint={activePoint}
                         setActivePoint={setActivePoint}
                         setState={setState}
                         activeDatumIndex={activeDatumIndex}
                         activeSeriesIndex={activeSeriesIndex}
                />
            </div>
        </div>
        <div className={styles.graphics_line}>
            <div className={styles.graphic_elem}>
                <MyChart data={data3}
                         height={"200px"}
                         name={METRICS_NAMES[2].bigLabel}
                         loading={loading}
                         primaryAxisType="time"
                         activePoint={activePoint}
                         setActivePoint={setActivePoint}
                         setState={setState}
                         activeDatumIndex={activeDatumIndex}
                         activeSeriesIndex={activeSeriesIndex}
                />
            </div>
            <div className={styles.graphic_elem}>
                <MyChart data={data4}
                         height={"200px"}
                         name={METRICS_NAMES[3].bigLabel}
                         loading={loading}
                         primaryAxisType="time"
                         activePoint={activePoint}
                         setActivePoint={setActivePoint}
                         setState={setState}
                         activeDatumIndex={activeDatumIndex}
                         activeSeriesIndex={activeSeriesIndex}
                />
            </div>
        </div>
        <div className={styles.graphic_elem}>
            <div className={styles.activity_div}>
                <div className={styles.head_div}>
                    Входы каждые полчаса за последние дни
                </div>
                <div className={styles.activity_inner_div} style={{justifyContent: "space-between"}}>
                    <div className={styles.activity_inner_div}>
                    <div style={{marginRight: '20px'}}>
                        <AppSelectedList data={TimeData}
                                         activeIndex={innerTime}
                                         setActiveIndex={setInnerTime}
                                         width={'130px'}
                        />
                    </div>
                    <div style={{marginRight: '20px'}}>
                        <AppSelectedList data={EntersTypeData}
                                         activeIndex={entersType}
                                         setActiveIndex={setEntersType}
                        />
                    </div>
                    </div>
                    <AppButton onClick={() => GetEntersGraph()}>
                        Показать
                    </AppButton>
                </div>
            </div>
            <div className={styles.graphic_elem}>
            <AppGraph data={data5}
                     height={"200px"}
                     name={ENTERS_METRICS[entersType].bigLabel}
                     primaryAxisType="time"
                     // activePoint={activePoint}
                     // setActivePoint={setActivePoint}
                     // setState={setState}
                     // activeDatumIndex={activeDatumIndex}
                     // activeSeriesIndex={activeSeriesIndex}
            />
            </div>
            <div className={styles.graphic_elem}>
                <AppGraph data={data6}
                          height={"320px"}
                          name={ENTERS_METRICS[entersType].bigLabel + " (суммарно)"}
                          primaryAxisType="time"
                    // activePoint={activePoint}
                    // setActivePoint={setActivePoint}
                    // setState={setState}
                    // activeDatumIndex={activeDatumIndex}
                    // activeSeriesIndex={activeSeriesIndex}
                />
            </div>
        </div>

    </div>
    </AdminStatisticsPage>
}

export default StatisticUsersPage;