import React, {useEffect, useState} from 'react';
import {AppModal} from "../../components/AppModal";
import styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {MAIN_URL, PERIODS, SECTIONS} from "../../constants";
import cn from 'classnames';
import StudentStatisticsPage from "../StudentStatisticsPage";


export const GetClassroomItem = ({name, id, active, index, onClick}) => {
    return <div key={index.toString()}
                onClick={onClick}
                className={cn(styles.classroom_div, active && styles.classroom_div_active)}>
        {name}
    </div>
}

export const GetStudentItem = ({name, id, active=false, index, onClick}) => {
    return <div key={index.toString()}
                onClick={onClick}
                className={cn(styles.student_item_div, active && styles.student_item_div_active)}>
        {name}
    </div>
}


export const GetNumber = (numb) => {
    try {
        return Number(numb)
    } catch (e) {
        return -1
    }
}


function ClassroomStatisticsPage({history, match}) {

    const classroomId = GetNumber(match.params.classroomId);
    const studentId = GetNumber(match.params.studentId);

    const classrooms = useSelector(state => state.classroom.classrooms)
    const users = useSelector(state => state.classroom.users)

    const [loading, setLoading] = useState(true);

    const WaitAndThen = (handler = () => {}) => {
        setLoading(true)
        setTimeout(() => {
            handler()
            setLoading(false);
        }, waiting)
    }

    const waiting = 800;
    useEffect(() => {
        //setLoading(true)
        const timeout = setTimeout(() => setLoading(false), waiting)
        return () => clearTimeout(timeout)
    }, [])

    const [activeClassroomIndex, setActiveClassroomIndex] = useState(
        classroomId ? classrooms.findIndex(c => c.id === Number(classroomId)) : -1)
    const [activeStudentIndex, setActiveStudentIndex] = useState(-1)


    const onChooseClassroom = (id) => {
        history.push(MAIN_URL.CLASSROOM_STATISTICS + '/' + id)
    }

    const [students, setStudents] = useState([])

    useEffect(() => {
        if (classrooms.findIndex(c => c.id === classroomId) >= 0) {
            const studentsIds = classrooms.find(c => c.id === classroomId).students
            setStudents(users.filter(u => studentsIds.findIndex(s => s === u.id) !== -1))
            // console.log("Students", classrooms.find(c => c.id === classroomId).students)
        }
    }, [classroomId])

    const [commonProgress, setCommonProgress] = useState([])

    useEffect(() => {
        if (classrooms.findIndex(c => c.id === classroomId) >= 0) {
            const statistics = classrooms.find(c => c.id === classroomId).statistics
            setCommonProgress(statistics)
            // console.log("Students", classrooms.find(c => c.id === classroomId).students)
        }
    }, [classroomId])

    useEffect(() => {
        if (classrooms.findIndex(c => c.id === classroomId) < 0) {
            history.push(MAIN_URL.CLASSROOM_STATISTICS)
        }
    }, [classroomId])

    const onChooseStudent = (id) => {
        if (id > 0) {
            history.push(MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId + '/' + id.toString())
        } else {
            if (classroomId && classroomId > 0) {
                history.push(MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId)
            } else {
                history.push(MAIN_URL.CLASSROOM_STATISTICS)
            }
        }
    }

    const studentStatPage = StudentStatisticsPage(
        {history,
            match,
            startURL: MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId + '/' + studentId})

    // useEffect(() => {
    //     setStudentStatPage(
    //         StudentStatisticsPage({history, match,
    //             startURL: MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId + '/' + studentId})
    //     )
    // }, [classroomId, studentId])

    useEffect(() => {
        // console.log("C_ID", classroomId)
        if (classroomId) {
            setActiveClassroomIndex(classrooms.findIndex(c => c.id === Number(classroomId)))
            // console.log("FIND", activeClassroomIndex, classrooms.findIndex(c => c.id === Number(classroomId)))
        } else {
            setActiveClassroomIndex(-1)
        }
    }, [classroomId])

    useEffect(() => {
        // console.log("SET STUD INDEX", studentId)
        if (studentId) {
            try {
                // console.log("ERROR 1111  !!!!", students
                //     .findIndex(s => s.id === studentId), students.map(e => e.id))
                setActiveStudentIndex(students
                    .findIndex(s => s.id === studentId))
            } catch (e) {
                // console.log("ERROR!!!!")
                setActiveStudentIndex(-1)
            }
        } else {
            setActiveStudentIndex(-1)
        }
    }, [studentId, students])

    return <div>
        {/*<div className={styles.full_white_screen}/>*/}
        <AppModal modalVisible={loading} setModalVisible={setLoading} canCloseOnBackdrop={false}
                  showLoading={true}/>
        <div className={cn(styles.stat_header, classroomId && styles.double_left)}>
            <div className={styles.stat_header_section_name}>
                {classroomId ? (studentId && (studentId >= 0) &&
                (students.findIndex(s => s.id === studentId) >= 0) ?
                    students.find(s => s.id === studentId).name : "Сводная статистика") : ""}
            </div>
            {/*<div className={styles.stat_time}>*/}
            {/*    <AppSelectedList data={SelectedTimeData}*/}
            {/*                     activeIndex={activeSelected}*/}
            {/*                     setActiveIndex={setActiveSelected}*/}
            {/*                     onChoose={onChooseTime}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
        {classroomId ? <div className={styles.choose_stat_type_block_second}>
            <div className={styles.choose_stat_type}>
                <div className={styles.classroom_list_header_text} style={{fontSize: "20px"}}>
                    Статистика
                </div>
                {GetStudentItem(
                    {
                        name: "Сводная",
                        id: -1,
                        index: -1,
                        active: -1 === activeStudentIndex,
                        onClick: () => WaitAndThen(() => onChooseStudent(-1))
                    })
                }
                <div className={styles.classroom_list_header_text} style={{fontSize: "16px"}}>
                    {`По ученикам (${students.length} учеников)`}
                </div>
                {students.map((student, index) => {
                    return GetStudentItem(
                        {
                            name: student.name,
                            id: student.id,
                            index,
                            active: index === activeStudentIndex,
                            onClick: () => WaitAndThen(() => onChooseStudent(student.id))
                        })
                })}
            </div>
        </div> : <div className={styles.choose_stat_type_block_second}>
            <div className={styles.choose_stat_type}>
                <div className={styles.classroom_list_header_text} style={{fontSize: "20px"}}>
                    Статистика
                </div>
            </div>
        </div>}
        <div className={styles.choose_stat_type_block}>
            <div className={styles.choose_stat_type}>
                <div className={styles.classroom_list_header_text}>
                    Группы
                </div>
                {classrooms.map((classroom, index) => {
                    return GetClassroomItem(
                        {
                            name: classroom.name,
                            id: classroom.id,
                            index,
                            active: index === activeClassroomIndex,
                            onClick: () => WaitAndThen(() => onChooseClassroom(classroom.id))
                        })
                })}
            </div>
        </div>

        <div className={cn(styles.main_content)}>
            {/*{classroomId > 0 && studentId > 0 && studentStatPage}*/}
            {classroomId > 0 ? (studentId > 0 ? studentStatPage :
                <div className={styles.main_div_common_stat}>
                    <div className={cn(styles.periods_line, styles.header_common)}>
                        <div className={cn(styles.section_name, styles.header_font)}>Раздел</div>
                        <div className={cn(styles.section_progress, styles.header_font)}>
                            Покрытие материала
                        </div>
                        <div className={cn(styles.section_progress, styles.header_font)}>
                            Линейный прогресс
                        </div>
                        <div className={cn(styles.section_level, styles.header_font)}>
                            Уровень знаний
                        </div>
                    </div>
                    {/*{Object*/}
                    {/*    .keys(SECTIONS)*/}
                    {/*    .map(sec => ({...SECTIONS[sec], key: sec}))*/}
                    {/*    .map((sec, i) => {*/}
                    {/*        const reducerLP =*/}
                    {/*            (acc, currentValue) => acc + currentValue.lp;*/}
                    {/*        const reducerTP =*/}
                    {/*            (acc, currentValue) => acc + currentValue.tp;*/}
                    {/*        const progress = sec.periods.map(period =>*/}
                    {/*            ({...PERIODS[period],*/}
                    {/*                lp: Math.round(Math.random() * 100)}))*/}
                    {/*            .map(period => ({...period,*/}
                    {/*                tp: Math.min(100, Math.round(period.lp * (1 + Math.random() / 2)))}))*/}
                    {/*        const TP = Math.round(progress.reduce(reducerTP, 0) / progress.length);*/}
                    {/*        const LP = Math.round(progress.reduce(reducerLP, 0) / progress.length);*/}
                    {students.length > 0 ? commonProgress.map(({progress, TP, LP, sec}, i) => {
                        return <div key={i.toString()} className={styles.period_and_section_block}>
                            <div className={styles.section_line}>
                                <div className={styles.section_name}>{sec.name}</div>
                                <div className={cn(styles.section_progress
                                    //, TP <= 30 && styles.red_color
                                    )}>{
                                    `${TP}%`
                                }</div>
                                <div className={cn(styles.section_progress
                                    //, TP <= 30 && styles.red_color
                                )}>{
                                    `${LP}%`
                                }</div>
                                <div className={cn(styles.section_level
                                    , TP <= 30 && styles.red_color
                                    , TP >= 80 && styles.green_color
                                    , TP > 30 && TP < 80 && styles.mean_color
                                )}>{
                                    (TP <= 30) ? "Низкий" : (
                                        TP < 80 ? "Средний" : "Высокий"
                                    )
                                }</div>
                            </div>
                            {progress.map((period, index) => (
                                <div className={styles.periods_line} key={index.toString()}>
                                    <div className={styles.section_name}>{period.name}</div>
                                    <div className={cn(styles.section_progress)}>{
                                        `${period.tp}%`
                                    }</div>
                                    <div className={cn(styles.section_progress)}>{
                                        `${period.lp}%`
                                    }</div>
                                    <div className={cn(styles.section_level
                                        , period.tp <= 30 && styles.red_color
                                        , period.tp >= 80 && styles.green_color
                                        , period.tp > 30 && period.tp < 80 && styles.mean_color
                                    )}>{
                                        (period.tp <= 30) ? "Низкий" : (
                                            period.tp < 80 ? "Средний" : "Высокий"
                                        )
                                    }</div>
                                </div>
                            ))}
                        </div>
                    }) : <div className={styles.classroom_list_header_text}>
                        Пока в группе нет ни одного ученика
                    </div>}
                </div>) :
                <div className={styles.black_div}/>
            }
        </div>

    </div>
}

export default ClassroomStatisticsPage;
