import React, {useEffect} from 'react';
import styles from "./styles.module.scss";
import {COOKIES_NAMES, MAIN_URL, ROLES} from "../../constants";

const studentElements = [
    {
        key: "0",
        name: "Занятия",
        link: MAIN_URL.CLASSROOM,
    },
    {
        key: "1",
        name: "Рейтинг",
        link: MAIN_URL.RATING,
    },
    {
        key: "2",
        name: "Моя статистика",
        link: MAIN_URL.STATISTICS,
    },
    {
        key: "3",
        name: "Лайфхаки",
        link: MAIN_URL.LIFE_HACKS,
    },
    {
        key: "4",
        name: "Профиль",
        link: MAIN_URL.PROFILE,
    }
]

const teacherElements = [
    {
        key: "0",
        name: "Конструктор тестов",
        link: MAIN_URL.TEST_CREATE,
    },
    {
        key: "1",
        name: "Мои группы",
        link: MAIN_URL.MY_CLASSROOMS,
    },
    {
        key: "2",
        name: "Статистика учеников",
        link: MAIN_URL.CLASSROOM_STATISTICS,
    },
    {
        key: "3",
        name: "Профиль",
        link: MAIN_URL.PROFILE,
    }
]

const adminElements = [
    {
        key: "0",
        name: "Занятия",
        link: MAIN_URL.CLASSROOM,
    },
    {
        key: "1",
        name: "Статистика",
        link: MAIN_URL.APP_STATISTICS,
    },
    {
        key: "2",
        name: "Профиль",
        link: MAIN_URL.PROFILE,
    }
]

function Header({onClick, role}) {

    const elements = role === ROLES.TEACHER ? teacherElements :
        (role === ROLES.ADMIN ? adminElements : studentElements);

    return <div className={styles.header_main}>
        <div className={styles.header__inner}>
            {elements.map(elem =>
                <div key={elem.key} className={styles.header_elem} onClick={() => {
                    onClick(elem.link);
                    document.title = elem.name;
                }}>
                    {elem.name}
                </div>
            )}
        </div>
    </div>;
}


export default Header;
