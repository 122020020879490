import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import cn from 'classnames';
import {ADMIN_STATISTICS_URL, DB_PATH, MAIN_URL} from "../../constants";
import AppSelectedList from "../../components/AppSelectedList";
import StatisticUsersPage from "./InnerPages/users";
import StatisticProductsPage from "./InnerPages/products";
import {useDispatch} from "react-redux";
import {setTime} from "../../store/actions/statistics";
import StatisticConversionsPage from "./InnerPages/conversions";
import StatisticActivityPage from "./InnerPages/activity";
import StatisticProductsRatingPage from "./InnerPages/productsRating";


export const GetTime = ({seconds, userDate = ""}) => {
    if (userDate) {
        return new Date(userDate);
    }
    const startDate = new Date();
    startDate.setHours(0)
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    return new Date(startDate.getTime() + seconds * 1000);
    let hours   = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    return hours+':'+minutes;
}


const STAT_TYPES = {
    STAT_SIMPLE: 'stat_simple',
    USERS: 'users',
    PRODUCTS: 'products',
    CONVERSIONS: "conversions",
    ACTIVITY: "activity",
    PRODUCTS_RATING: "products_rating",
}

const dataLeft = [
    {
        key: "0",
        name: 'Сводная статистика',
        value: STAT_TYPES.USERS
    },
    // {
    //     key: "1",
    //     name: "Продукты (сводная)",
    //     value: STAT_TYPES.PRODUCTS
    // },
    {
        key: "2",
        name: "Конверсии",
        value: STAT_TYPES.CONVERSIONS
    },
    {
        key: "3",
        name: "Активности",
        value: STAT_TYPES.ACTIVITY
    },
    {
        key: "4",
        name: "Анализ популярных продуктов",
        value: STAT_TYPES.PRODUCTS_RATING,
    }
];

const StatisticVariant = (elem, onClick) => {
    return <div onClick={onClick} className={cn(styles.stat_elem, elem.active && styles.active_stat_elem)} key={elem.key}>
        {elem.name}
    </div>
}

// const data_ = [[
//     {
//         label: "A",
//         data: [{x:"3 feb", y:1}, {x:2, y:2}, {x: "5 mar", y: 5}, {x: "5 marf", y: 5}]
//     },
//     {
//         label: "BBB",
//         data: [{x:"3 feb", y:3}, {x:2, y:1.5}, {x: "5 mar", y: 3}, {x: "5 marf", y: 5.6}]
//     }
// ]]

export const METRICS_NAMES = [
    {
        name: "unique_users",
        key: "0",
        label: "Пользователи",
        bigLabel: "Уникальные пользователи",
        short_label: "",
    },
    {
        name: "entered_users",
        key: "1",
        label: "Заходы",
        bigLabel: "Заходы в приложение",
        short_label: "",
    },
    {
        name: "completed_products",
        key: "2",
        label: "Продукты",
        bigLabel: "Пройденные продукты",
        short_label: "",
    },
    {
        name: "user_completed",
        key: "3",
        label: "Пользователи",
        bigLabel: "Количество пользователей, которые использовали продукты в этот день",
        short_label: "",
    },
    {
        name: "unique_users_times",
        key: "4",
        label: "Пользователи",
        bigLabel: "Входы уникальных пользователей каждые 30 минут",
        short_label: "",
    },
    {
        name: "users_times",
        key: "5",
        label: "Пользователи",
        bigLabel: "Все входы пользователей каждые 30 минут",
        short_label: "",
    },
]

export const SimpleGraphDataRequest = (R, metric, setD, label, time) => {
    R.GET({request: DB_PATH.STATISTICS.METRIC.N + `?metric=${metric.name}&time=${time}`,
        meta: {ms: 300000}}).then(res => {
        try {
            const xAxes = res.json.data.map(elem => GetTime({userDate: elem.d}))
            setD([
                {
                    label: metric.label,
                    bigLabel: metric.bigLabel,
                    config: {type: "gray"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.v}))
                },
                {
                    label: statMeanLabel,
                    bigLabel: metric.bigLabel + statMeanBigLabel,
                    config: {type: "median"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.m}))
                }
            ])
        } catch (e) {
            console.log(e)
            // setError("Error: " + e)
        }
    })
}

export const SelectedTimeData = [
    {id: 0, key: "0", name: "За 3 дня", value: 3},
    {id: 1, key: "1", name: "За 7 дней", value: 7},
    {id: 2, key: "2", name: "За 14 дней", value: 14},
    {id: 3, key: "3", name: "За 30 дней", value: 30},
    // {id: 4, key: "4", name: "За 30 дней", value: 30},
    // {id: 5, key: "5", name: "За 30 дней", value: 30},
]

// const labels = ["Юзеры", "Продукты"];
export const statMeanLabel = "Среднее";//"Скользящее среднее за 30 дней";
export const statMeanBigLabel = " (скользящее среднее за 30 дней)"


const InnerScreensList = [
    {id: 0, key: "0", path: ADMIN_STATISTICS_URL.USERS, screen: StatisticUsersPage},
    // {id: 1, key: "1", path: ADMIN_STATISTICS_URL.PRODUCTS, screen: StatisticProductsPage},
    {id: 1, key: "1", path: ADMIN_STATISTICS_URL.CONVERSIONS, screen: StatisticConversionsPage},
    {id: 2, key: "2", path: ADMIN_STATISTICS_URL.ACTIVITY, screen: StatisticActivityPage},
    {id: 3, key: "3", path: ADMIN_STATISTICS_URL.PRODUCTS_RATING, screen: StatisticProductsRatingPage},
]

const GetScreen = (path) => {
    if (path === undefined) {
        return InnerScreensList[0]
    }
    const ind = InnerScreensList.findIndex(elem => elem.path === path)
    if (ind >= 0) {
        return InnerScreensList[ind]
    }
    return InnerScreensList[0]
}


export function AdminStatisticsPage({history, screenId, children, time}) {

    // const Screen = GetScreen(history.location.pathname.split("/")[-1]);
    // const category = match.params.category;
    // const Screen = GetScreen(category);

    const [activeLeft, setActiveLeft_] = useState(screenId);
    const setActiveLeft = (id) => {
        //setActiveLeft_(id);
        history.push(MAIN_URL.APP_STATISTICS + "/" + InnerScreensList[id].path)
    }


    const dispatch = useDispatch();
    const onChooseTime = (id) => dispatch(setTime(SelectedTimeData[id].value))

    const SetTime = (t) => {
        const id = SelectedTimeData.findIndex(elem => elem.value === t);
        if (id >= 0) return id
        onChooseTime(1)
        return 1
    }

    const [activeSelected, setActiveSelected] = useState(SetTime(time));

    // const GetData = (metric, setD, label, another=false) => {
    //     REQUEST.GET({request: DB_PATH.STATISTICS.METRIC.N + `?metric=${metric.name}&time=${time}`,
    //         meta: {ms: 300000}}).then(res => {
    //         try {
    //             if (!another) {
    //                 const xAxes = res.json.data.map(elem => GetTime({userDate: elem.d}))
    //                 setD([
    //                     {
    //                         label: metric.label,
    //                         bigLabel: metric.bigLabel,
    //                         config: {type: "gray"},
    //                         data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.v}))
    //                     },
    //                     {
    //                         label: statMeanLabel,
    //                         bigLabel: metric.bigLabel + statMeanBigLabel,
    //                         config: {type: "median"},
    //                         data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.m}))
    //                     }
    //                 ])
    //             } else {
    //                 //console.log(res.json);
    //                 // return;
    //                 // const xAxes = res.json.dates
    //                 setD([...res.json.dates.map((date, i) => ({
    //                     label: date.substr(5),
    //                     bigLabel: metric.bigLabel + ` (${date.substr(5)})`,
    //                     config: {type: "gray"},
    //                     data: res.json.data.map((el, j) => ({
    //                         x: GetTime({seconds: el.t}),
    //                         y: el.enters[i]
    //                     }))
    //                 })), {
    //                     label: statMeanLabel,
    //                     bigLabel: metric.bigLabel + statMeanBigLabel,
    //                     config: {type: "median", color: "#3354FF", median: true},
    //                     data: res.json.data.map((el, j) => ({
    //                         x: GetTime({seconds: el.t}),
    //                         y: el.enters.reduce((sum, current) => sum + current, 0) / el.enters.length,
    //                     }))
    //                 }])
    //             }
    //         } catch (e) {
    //             console.log(e)
    //             setError("Error: " + e)
    //             setLoading(false)
    //         }
    //     })
    // }

    // const GetGraphs = () => {
    //     return InnerScreensList[activeLeft].screen({time})
    // }

    return <div>
        {/*<div className={styles.full_white_screen}/>*/}
        <div className={styles.choose_stat_type_block}>
            <div className={styles.choose_stat_type}>
                {dataLeft.map((elem, i) => {
                    return StatisticVariant({...elem, active: i === activeLeft},
                        () => setActiveLeft(i))
                })}
            </div>
        </div>

        <div className={styles.stat_header}>
            <div className={styles.stat_header_section_name}>
                {dataLeft[activeLeft].name}
            </div>
            <div className={styles.stat_time}>
                <AppSelectedList data={SelectedTimeData}
                                 activeIndex={activeSelected}
                                 setActiveIndex={setActiveSelected}
                                 onChoose={onChooseTime}
                />
            </div>
        </div>

        <div className={styles.main_content}>
            {children}
            {/*{Screen.screen({time, REQUEST})}*/}
            {/*/!*{GetGraphs()}*!/*/}
        </div>
    </div>
}

export default AdminStatisticsPage;
