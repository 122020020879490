import React, {useState} from 'react';
import AppButton from "../../../components/AppButton";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {AppModal} from "../../../components/AppModal";


function StudentProfilePage() {

    const {REQUEST} = RequestWrapper()

    const [name, setName] = useState("who?")

    const onExit = () => {
        REQUEST.CREATED.EXIT()
    }

    const onGetInfo = () => {
        REQUEST.GET({request: "user/"}).then(res => {
            try {
                console.log("Profile user info", res.json)
                setName(res.json.username)
            } catch (e) {
                console.log("ERR", e);
            }
        })
    };

    return <div>
        <h1>ПРОФИЛЬ STUDENT!</h1>
        <div style={{marginTop: "50px", marginLeft: "50px"}}>
            <AppButton onClick={onExit} type="red" style={{marginBottom: "50px"}}>Выйти</AppButton>
            <AppButton onClick={onGetInfo}>Проверка инфы</AppButton>
            <p>You are {name}</p>
        </div>
    </div>
}

export default StudentProfilePage;
