import React, {} from "react";
import {Route, Switch} from 'react-router-dom';
import {ADMIN_STATISTICS_URL, MAIN_URL} from "../constants";
import AdminStatisticsPage from "../pages/AdminStatisticsPage";
import StatisticUsersPage from "../pages/AdminStatisticsPage/InnerPages/users";
import StatisticProductsPage from "../pages/AdminStatisticsPage/InnerPages/products";
import StatisticConversionsPage from "../pages/AdminStatisticsPage/InnerPages/conversions";
import StatisticActivityPage from "../pages/AdminStatisticsPage/InnerPages/activity";
import StatisticProductsRatingPage from "../pages/AdminStatisticsPage/InnerPages/productsRating";

const GetLocalRoute = ({path}) => {
    console.log("PP", MAIN_URL.APP_STATISTICS + "/" + path)
    return <Route exact path={MAIN_URL.APP_STATISTICS + "/" + path}
                  render={() => AdminStatisticsPage({category: path})}/>
}

const InnerScreensList = [
    {id: 0, key: "0", path: ADMIN_STATISTICS_URL.USERS, screen: StatisticUsersPage},
    {id: 1, key: "1", path: ADMIN_STATISTICS_URL.PRODUCTS, screen: StatisticProductsPage},
]

const AdminStatisticsRouter = () => {

    return <Switch>
        <Route exact path={MAIN_URL.APP_STATISTICS}
               component={StatisticUsersPage}/>
        {/*<Route exact path={MAIN_URL.APP_STATISTICS + "/:category"}*/}
        {/*       component={AdminStatisticsPage}/>*/}
        <Route exact path={MAIN_URL.APP_STATISTICS + "/" + ADMIN_STATISTICS_URL.USERS}
               component={StatisticUsersPage}/>
        <Route exact path={MAIN_URL.APP_STATISTICS + "/" + ADMIN_STATISTICS_URL.PRODUCTS}
               component={StatisticProductsPage}/>
        <Route exact path={MAIN_URL.APP_STATISTICS + "/" + ADMIN_STATISTICS_URL.CONVERSIONS}
               component={StatisticConversionsPage}/>
        <Route exact path={MAIN_URL.APP_STATISTICS + "/" + ADMIN_STATISTICS_URL.ACTIVITY}
               component={StatisticActivityPage}/>
        <Route exact path={MAIN_URL.APP_STATISTICS + "/" + ADMIN_STATISTICS_URL.PRODUCTS_RATING}
               component={StatisticProductsRatingPage}/>
    </Switch>
};


export default AdminStatisticsRouter;
