import React, {useEffect} from 'react';
// import styles from "./styles.module.scss";
import Content from "../../components/Content";


function ClassroomPage() {

    useEffect(() => {
        document.title = "Занятия";
    }, [])

    return <div>
        <h1>ЗАНЯТИЯ</h1>
    </div>
}

export default ClassroomPage;
