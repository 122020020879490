import React from 'react';
import styles from "./styles.module.scss";
import cn from 'classnames';
import {AppActivityIndicator} from "../AppActivityIndicator";


export const AppModal=({modalVisible, setModalVisible, children,
                           canCloseOnBackdrop=true, showLoading=false}) => {

    const WrapperFunction = canCloseOnBackdrop ? () => setModalVisible(false) : () => {};

    return <div
        className={modalVisible ? cn(styles.modal_background, styles.active) : styles.modal_background}
        onClick={WrapperFunction}
    >
        {showLoading ? <AppActivityIndicator/> :
            <div className={modalVisible ? cn(styles.modal_div, styles.active_inner) : styles.modal_div}
                 onClick={e => e.stopPropagation()}>
                {children}
            </div>
        }
    </div>
};
