// export const C_USER = {
//     CHANGE_DATA: "CHANGE_DATA",
//     CHANGE_AVATAR: "CHANGE_AVATAR",
//     ADD_USER: "ADD_USER",
//     REMOVE_USER: "REMOVE_USER"
// };

export const START_URL = "/app";

export const MAIN_URL = {
    ABOUTUS: "/about_us",
    PROFILE: "/profile",
    ADMIN_PROFILE: "/adminprofile",
    STATISTICS: "/statistics",
    APP_STATISTICS: "/appstat",
    CLASSROOM: "/classroom",
    CLASSROOM_STATISTICS: "/classroomstat",
    TEST_CREATE: "/createtests",
    MY_CLASSROOMS: "/myclassrooms",
    LIFE_HACKS: "/lifehacks",
    COMPETITION: "/competition",
    RATING: "/rating",
    LANDING: "/landing",
    NOT_FOUND: "/notfound",
};

export const ADMIN_STATISTICS_URL = {
    USERS: "users",
    PRODUCTS: "products",
    CONVERSIONS: "conversions",
    ACTIVITY: "activity",
    PRODUCTS_RATING: "products_rating",
}

export const METRICS = {
    UNIQUE_USERS: 'unique_users',
    ENTERED_USERS: 'entered_users',
    COMPLETED_PRODUCTS: 'completed_products',
    USER_COMPLETED: 'user_completed',
}

export const DB_SP = {
    USER: "user/",
    CONTENT: "content/",
    STATISTICS: "statistics/",
    LESSONS: "lessons/",
    METRIC: "metric/",
    INIT: "init/",

}

export const DB_PATH = {
    USER: {
        N: DB_SP.USER,
    },
    INIT: {
        N: DB_SP.INIT,
    },
    CONTENT: {
        N: DB_SP.CONTENT,
    },
    STATISTICS: {
        N: DB_SP.STATISTICS,
        METRIC: {
            N: DB_SP.STATISTICS + DB_SP.METRIC
        }
    }
}

export const DB_URL = {
    URL: "https://mindon.space/api",
    SECOND_URL: "http://185.148.82.169:8000", //"http://mindon.space", //"http://localhost:8080",
};

export const COOKIES_NAMES = {
    TOKEN: "token",
    ROLE: "role",
    STATUS: "status",
    USER_NAME: "user_name",
    ACCEPT: "accept"
}

export const STATUS_USER_COOKIES = {
    USER: "user",
    ADMIN: "admin",
}

export const ROLES = {
    STUDENT: "student",
    TEACHER: "teacher",
    ADMIN: "admin",
}

export const DESIGN = {
    MARGIN_TOP_HEADER: "45px",
}

export const SECTIONS = {
    anc: {name: "Древность и средневековье", periods: ["01", "02", "03", "04"]},
    ren: {name: "Новое время", periods: ["05", "06", "07"]},
    mod: {name: "Новейшее время", periods: ["08", "09"]},
}

export const PERIODS = {
    "01" : {name: "IX – XII вв." , description : "Образование Древнерусского государства Киевской Руси"},
    "02" : {name: "XII – XIII вв.", description: "Период феодальной раздроблености, начало монгольского владычества"},
    "03" : {name: "Конец XIII – первая половина XV в.", description: "Возвышение Москвы, начало объединения русских земель"},
    "04" : {name: "Вторая половина XV – начало XVI в.", description: "Завершение объединения русских земель вокруг Москвы, образование Русского централизованного государства"},
    "05" : {name: "XVI – XVII вв.", description: "Становление российского самодержавия, смутное время, правление первых Романовых"},
    "06" : {name: "XVIII – конец XIX в.", description: "Становление и расцвет Российской империи"},
    "07" : {name: "Конец XIX – начало XX в.", description: "Падение Российской империи, Временное правительство России"},
    "08" : {name: "XX в.", description: "Советская республика"},
    "09" : {name: "Конец XX – начало XXI в.", description: "Российская Федерация "},
}

export const PERSONS = {
    "1": {name: "Иван III Васильевич", description:	"1462 - 1505"},
    "2": {name: "Василий III Иванович", description: "1505 - 1533"},
    "3": {name: "Елена Васильевна Глинская (регент при Иване IV)", description:	"1533 - 1548"},
    "4": {name: "Иван IV Васильевич \"Грозный\"", description:	"1548 - 1584"},
    "5": {name: "Федор I Иванович", description: "1584 - 1598"},
    "6": {name: "Борис Федорович Годунов", description:	"1598 - 1605"},
    "7": {name: "Федор Борисович Годунов", description:	"1605 - 1605"},
    "8": {name: "Лжедмитрий I", description: "1605 - 1606"},
    "9": {name: "Василий IV Шуйский", description: "1606 - 1610"},
    "10": {name: "Семибоярщина", description: "1610 - 1612"},
    "11": {name: "Михаил Федорович Романов", description: "1613 - 1645"},
    "12": {name: "Алексей Михайлович Романов Тишайший", description: "1645 - 1676"},
    "13": {name: "Федор Алексеевич Романов", description: "1676 - 1682"},
    "14": {name: "Софья Алексеевна Романова (регент при Петре I и Иване V)", description: "1682 - 1689"},
    "15": {name: "Петр I Алексеевич", description: "1689 - 1725"},
    "16": {name: "Екатерина I Алексеевна", description: "1725 - 1727"},
    "17": {name: "Петр II Алексеевич", description: "1727 - 1730"},
    "18": {name: "Анна Иоанновна", description: "1730 - 1740"},
    "19": {name: "Иван VI Антонович (свергнут малолетним)", description: "1740 - 1741"},
    "20": {name: "Елизавета Петровна", description: "1741 - 1761"},
    "21": {name: "Петр III Федорович", description: "1761 - 1762"},
    "22": {name: "Екатерина II Алексеевна", description: "1762 - 1796"},
    "23": {name: "Павел I Петрович", description: "1796 - 1801"},
    "24": {name: "Александр I Павлович", description: "1801 - 1825"},
    "25": {name: "Николай I Павлович", description: "1825 - 1855"},
    "26": {name: "Александр II Николаевич", description: "1855 - 1881"},
    "27": {name: "Александр III Александрович", description: "1881 - 1894"},
    "28": {name: "Николай II Александрович", description: "1894 - 1917"},
    "29": {name: "Период Временного правительства", description: "1917"},
    "30": {name: "Ленин Владимир Ильич", description: "1917 - 1924"},
    "31": {name: "Сталин Иосиф Виссарионович", description: "1924 - 1953"},
    "32": {name: "Хрущёв Никита Сергеевич", description: "1953 - 1964"},
    "33": {name: "Брежнев Леонид Ильич", description: "1964 - 1982"},
    "34": {name: "Андропов Юрий Владимирович", description: "1982 - 1984"},
    "35": {name: "Черненко Константин Устинович", description: "1984 - 1985"},
    "36": {name: "Горбачёв Михаил Сергеевич", description: "1985 - 1991"},
    "37": {name: "Ельцин Борис Николаевич", description: "1991 - 1999"},
    "38": {name: "Путин Владимир Владимирович", description: "1999 - 2008"},
    "39": {name: "Медведев Дмитрий Анатольевич", description: "2008 - 1012"},
    "40": {name: "Путин Владимир Владимирович", description: "2012 - наши дни"},
}

export const TAGS = {
    da: { name: "Даты", tag: "da" },
    pe: { name: "Личности", tag: "pe"},
    ev: { name: "События", tag: "ev"},
    ec: { name: "Экономика", tag: "ec" },
    re: { name: "Реформы", tag: "re" },
    te: { name: "Термины и понятия", tag: "te" },
    ma: { name: "Карты и география", tag: "ma" },
    cu: { name: "Наука и культура", tag: "cu" },
    wa: { name: "Войны и битвы", tag: "wa" },
    po: { name: "Власть и политика", tag: "po" },
    ca: { name: `Причинно-следственные связи`, tag: "ca" },
    tr: { name: "Мирные соглашения и договоры", tag: "tr" },
};