import React  from "react";
import {AppLogo} from "../AppLogo/index"
import styles from "./styles.module.scss";
//import cn from "classnames";

export const AppActivityIndicator = ({style={}, type="big"}) => {
    const myType = type === "big" ? "big" : "small";
    return (
            <div className={styles.container}>
                <div className={myType === "big" ? styles.animated : styles.animated_small}>
                    <AppLogo />
                </div>
            </div>
    );
};

