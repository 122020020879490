import React, {useEffect, useRef, useState} from 'react';
import styles from "./styles.module.scss";
import {withRouter} from "react-router-dom";
import {MAIN_URL, PERSONS} from "../../constants";
import AppButton from "../../components/AppButton";
import EnterForm from "../../containers/EnterForm";
import ScrollableAnchor , { goToAnchor, removeHash }  from 'react-scrollable-anchor';
import RequestWrapper from "../../helpers/RequestWrapper";
import cn from "classnames";
import {THEME} from "../../constants/THEME";
import AppCircularProgress from "../../components/UI/AppCircularProgress";

const FullScreen = ({children, style={}, classname}) => {
    return <div className={cn(styles.full_screen, classname)} style={style}>
        {children}
    </div>
}

class PageSection extends React.Component {
    render() {
        return this.props.content
    }
}
// PageSection.propTypes = {
//     content: React.PropTypes.node.isRequired
// }


function LandingPage({history}) {

    const afterEnter = () => {
        window.scroll(0, 0);
        history.push(MAIN_URL.PROFILE)
    }

    const {REQUEST} = RequestWrapper({children: (p) => ({...p})})

    const exampleWithoutAuth = () => {
        REQUEST.GET({request: "statistics/"}).then(res => console.log("UnAuth", res.status))
    }

    useEffect(() => {
        document.title = "Mind ON | История";
    }, [])

    const [circleData, setCircleData] = useState([
            {...PERSONS["28"], percentage: 30},
            {...PERSONS["29"], percentage: 70},
            {...PERSONS["30"], percentage: 82},
            {...PERSONS["31"], percentage: 12},
            {...PERSONS["32"], percentage: 36}
        ])

    useEffect(() => {
        let interval = setTimeout(() => {
            setCircleData(c => c.map((elem) =>
                ({...elem, percentage: Math.round(Math.random() * 100)})))
        }, 2500)
        return () => clearTimeout(interval)
    }, [circleData])


    const mainRadius = 150;

    const [radius, setRadius] = useState(mainRadius)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [yTotal, setYTotal] = useState(0)
    const [scroll, setScroll] = useState(0)

    const handleScroll = (e) => {
        // console.log("SCROLL", e)
        setTimeout(() => {
            // setX(x => e.clientX)
            setScroll(window.scrollY)
            //setRadius(150)
        }, 50)
    }

    const handleMouseMove = (e) => {
        setTimeout(() => {
            setX(x => e.clientX)
            setY(e.clientY)
            //setRadius(150)
        }, 50)
    }

    const handleBigMouseMove = (e, rad = 250) => {
        setTimeout(() => {
            setX(x => e.clientX)
            setY(e.clientY)
            setRadius(rad)
        }, 50)
    }

    const handleBigMouseLeave = (e, rad = mainRadius) => {
        setTimeout(() => {
            setX(x => e.clientX)
            setY(e.clientY)
            setRadius(rad)
        }, 50)
        // setRadius(250)
    }

    useEffect(() => {
        setYTotal(y + scroll)
    }, [scroll, y])

    useEffect(() => {
        // const img = document.getElementById("image");
        // const imgPos = img.getBoundingClientRect();
        // var imgX = imgPos.left;
        // var imgY = imgPos.top;
        // var rect = document.getElementsByTagName("rect")[1];
        // var rectHalfWidth = rect.getAttribute("width") / 2;
        // var rectHalfHeight = rect.getAttribute("height") / 2;
        // img.addEventListener("mousemove", function (e) {
        //     rect.setAttribute("x", e.clientX - imgX - rectHalfWidth);
        //     rect.setAttribute("y", e.clientY - imgY - rectHalfHeight);
        // }, false);

        const img = document.getElementById("circle");
        const svg = document.getElementById("svg");
        const title = document.getElementById("title");
        const subtitle = document.getElementById("subtitle")
        const secondCentral = document.getElementById("second_central")
        // const imgPos = img.getBoundingClientRect();
        // var imgX = imgPos.left;
        // var imgY = imgPos.top;
        window.addEventListener("scroll", handleScroll, false)
        window.addEventListener("mousemove", handleMouseMove, false);
        title.addEventListener("mousemove",
            (e) => handleBigMouseMove(e, 450), false)
        title.addEventListener("mouseleave",
            (e) => handleBigMouseLeave(e, mainRadius), false)
        subtitle.addEventListener("mousemove",
            (e) => handleBigMouseMove(e, 30), false)
        subtitle.addEventListener("mouseleave",
            (e) => handleBigMouseLeave(e, mainRadius), false)
        secondCentral.addEventListener("mousemove",
            (e) => handleBigMouseMove(e, 350), false)
        secondCentral.addEventListener("mouseleave",
            (e) => handleBigMouseLeave(e, mainRadius), false)
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("mousemove", handleMouseMove);
        }
    }, [])

    const h = window.innerHeight;

    return <div>
        <svg className={styles.svg} id={"svg"}>
            <defs>
                <mask id="cursorMask"
                      maskUnits="objectBoundingBox"
                    //maskContentUtils="objectBoundingBox"
                >
                    {/*<g>*/}
                    <rect width="100%" height="100%" x="0" y="0"
                          fill="white"
                          opacity="1"
                    />
                    <g
                        //transform="translate(0, 0)"
                    >
                        <text
                            textAnchor="middle"
                            x="50%"
                            y="50%"
                            className={styles.text_text}
                            fill="#000"
                            //id="title"
                        >
                            Mind ON
                        </text>
                        <circle cx={x} cy={yTotal} r={radius} id="circle"
                            fill='rgba(0, 0, 0, 1)' className={styles.circle}
                        />
                    </g>
                    {/*</g>*/}
                </mask>
                {/*<rect x="0" y="0" className="one" mask="url(#mask)" width="600" height="300"/>*/}
            </defs>
            <defs>
                <mask id="cursorOnlyMask"
                      maskUnits="objectBoundingBox">
                    <rect width="100%" height="100%" x="0" y="0"
                          fill="white"
                          opacity="1"
                    />
                    <g>
                        <circle cx={x} cy={yTotal} r={radius} id="circle"
                                fill='rgba(0, 0, 0, 1)' className={styles.circle}
                        />
                    </g>
                </mask>
            </defs>
            <defs>
                <mask id="textMask"
                      maskUnits="objectBoundingBox">
                    <rect width="100%" height="100%" x="0" y="0"
                          fill="#000"
                          opacity="1"
                    />
                    <text
                        textAnchor="middle"
                        x="50%"
                        y="50%"
                        className={cn(styles.text_text)}
                        fill="#fff"
                        // mask="url(#cursorOnlyMask)"
                        //id="title"
                    >
                        Mind ON
                    </text>
                </mask>
            </defs>
                <text
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    className={styles.text_text}
                    fill={THEME.ORANGE_500}
                    //mask="url(#cursorOnlyMask)"
                    //id="title"
                >
                    Mind ON
                </text>
            <text
                textAnchor="middle"
                x="50%"
                y="50%"
                className={cn(styles.text_text, styles.back_image)}
                fill={THEME.BLUE_500}
                mask="url(#cursorOnlyMask)"
                //id="title"
            >
                Mind ON
            </text>

            {/*</rect>*/}
            <rect x="0" y="0" className={styles.image} id="image"
                  //mask="url(#mask)"
                  width="100%" height="100%"
                  //fill={THEME.GRAY_200}
                  fill={"#fff"}
            >
            </rect>

            {/*<rect x="0" y="0"*/}
            {/*      className={styles.back_image}*/}
            {/*      id="image2"*/}
            {/*      mask="url(#textMask)"*/}
            {/*      width="100%" height="100%"*/}
            {/*      fill="#000"*/}
            {/*>*/}
            {/*</rect>*/}

            {/*<div className={styles.image} id="image"*/}
            {/*     style={{width: "100%", height: "100vh", backgroundColor: "#fff"}}/>*/}
            {/*<image width="520" height="166" id="image" className={styles.image}*/}
            {/*       //xlink:*/}
            {/*       href="https://upload.wikimedia.org/wikipedia/commons/d/d4/Firefox-33-xfce.png" />*/}
        </svg>
        <svg className={styles.svg2} id={"svg2"}>
            <defs>
                <mask id="cursorMask2"
                      maskUnits="objectBoundingBox"
                    //maskContentUtils="objectBoundingBox"
                >
                    {/*<g>*/}
                    <rect width="100%" height="100%" x="0" y="0"
                          fill="white"
                          opacity="1"
                    />
                    <g>
                        <text
                            textAnchor="middle"
                            width="400px"
                            x="50%"
                            y="40%"
                            className={styles.text_text2}
                            fill="#000"
                            //id="title"
                        >
                            <tspan x="70%" y="30%" dy="1em">Подробная</tspan>
                            <tspan x="70%" y="40%" dy="1em">статистика и</tspan>
                            <tspan x="70%" y="50%" dy="1em">прогресс по всем</tspan>
                            <tspan x="70%" y="60%" dy="1em">разделам истории!</tspan>
                        </text>
                        <circle cx={x} cy={yTotal - h} r={radius} id="circle"
                                fill='rgba(0, 0, 0, 1)' className={styles.circle}
                        />
                    </g>
                </mask>
            </defs>
            <defs>
                <mask id="cursorOnlyMask2"
                      maskUnits="objectBoundingBox">
                    <rect width="100%" height="100%" x="0" y="0"
                          fill="white"
                          opacity="1"
                    />
                    <g>
                        <circle cx={x} cy={yTotal - h} r={radius} id="circle"
                                fill='rgba(0, 0, 0, 1)' className={styles.circle}
                        />
                    </g>
                </mask>
            </defs>
            <defs>
                <mask id="textMask2"
                      maskUnits="objectBoundingBox">
                    <rect width="100%" height="100%" x="0" y="0"
                          fill="#000"
                          opacity="1"
                    />
                    <text
                        textAnchor="middle"
                        x="50%"
                        y="40%"
                        width="400px"
                        className={cn(styles.text_text2)}
                        fill="#fff"
                    >
                        <tspan x="70%" y="30%" dy="1em">Подробная</tspan>
                        <tspan x="70%" y="40%" dy="1em">статистика и</tspan>
                        <tspan x="70%" y="50%" dy="1em">прогресс по всем</tspan>
                        <tspan x="70%" y="60%" dy="1em">разделам истории!</tspan>
                    </text>
                </mask>
            </defs>
            <text
                textAnchor="middle"
                x="50%"
                y="40%"
                //width="400px"
                className={styles.text_text2}
                fill={THEME.BLUE_500}
            >
                <tspan x="70%" y="30%" dy="1em">Подробная</tspan>
                <tspan x="70%" y="40%" dy="1em">статистика и</tspan>
                <tspan x="70%" y="50%" dy="1em">прогресс по всем</tspan>
                <tspan x="70%" y="60%" dy="1em">разделам истории!</tspan>
            </text>
            <text
                textAnchor="middle"
                x="50%"
                y="30%"
                className={cn(styles.text_text2, styles.back_image)}
                fill={THEME.ORANGE_500}
                mask="url(#cursorOnlyMask2)"
                //id="title"
            >
                <tspan x="70%" y="30%" dy="1em">Подробная</tspan>
                <tspan x="70%" y="40%" dy="1em">статистика и</tspan>
                <tspan x="70%" y="50%" dy="1em">прогресс по всем</tspan>
                <tspan x="70%" y="60%" dy="1em">разделам истории!</tspan>
            </text>

            <rect x="0" y="0" className={styles.image2} id="image2"
                  // mask="url(#cursorMask2)"
                //mask="url(#mask)"
                  width="100%" height="100%"
                  fill={THEME.BLUE_400}
            >
            </rect>
        </svg>
        <FullScreen classname={styles.first_body}>
            <div id="title"
                 onClick={() => goToAnchor('form', false)}
                 className={cn(styles.text_text, styles.title)}
                 style={{zIndex: 1000}}
            >
                Присоединиться
            </div>
            <div id="subtitle"
                 onClick={() => goToAnchor('form', false)}
                 className={cn(styles.text_text, styles.subtitle)}
                 style={{zIndex: 1000}}
            >
                У меня уже есть аккаунт
            </div>

            {/*<h1 style={{position: "absolute", color: THEME.ORANGE_500}}*/}
            {/*    className={styles.mask_elem}*/}
            {/*    id="title">This is Mind ON!</h1>*/}
            {/*<AppButton onClick={() => {*/}
            {/*    goToAnchor('form', false)*/}
            {/*    //removeHash()*/}
            {/*}}>*/}
            {/*    Войти*/}
            {/*</AppButton>*/}
        </FullScreen>
        <FullScreen
            style={{backgroundColor: "#000000"}}>

            <div className={cn(styles.white_box, styles.second_body)}
                 // style={{zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0)'}}
            >
                {/*<AppButton onClick={exampleWithoutAuth}>Пример запроса без авторизации</AppButton>*/}
                <div className={styles.centered_div}
                     id="second_central"
                     style={{zIndex: 1000}}
                >
                    {/*<div>*/}
                <AppCircularProgress
                    data={circleData}
                />
                    {/*</div>*/}
                <h3 className={styles.circular_right_header}>
                    {/*Подробная статистика и прогресс по всем разделам истории!*/}
                </h3>
                </div>
            </div>

        </FullScreen>
        <ScrollableAnchor id='form'>
            <PageSection content={
                <FullScreen style={{backgroundColor: "#000000"}}>
                    <div className={styles.white_box}>
                        {RequestWrapper((p) => EnterForm({onEnter: afterEnter, ...p}))}
                    </div>
                </FullScreen>
            }/>
        </ScrollableAnchor>
    </div>
}

export default withRouter(LandingPage);
