import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {MAIN_URL} from "../../constants";
import {AppModal} from "../../components/AppModal";
import {GetClassroomItem, GetNumber, GetStudentItem} from "../ClassroomStatisticsPage";
import {addClassroom, addTestToClassroom, deleteUserFromClassroom, editClassroom} from "../../store/actions/classrooms";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import cn from 'classnames';
import {ProgressLine} from "../../components/UI/ProgressLine";


function TeacherClassroomsPage({history, match}) {

    const classroomId = GetNumber(match.params.classroomId);
    //const studentId = GetNumber(match.params.studentId);

    const dispatch = useDispatch();
    const classrooms = useSelector(state => state.classroom.classrooms)
    const users = useSelector(state => state.classroom.users)
    const tests = useSelector(state => state.classroom.tests)
    // const allClassroom = useSelector(state => state.classroom)
    //
    // useEffect(() => {
    //     console.log("CLASSROOMS", allClassroom)
    // }, [allClassroom])

    const [loading, setLoading] = useState(true);

    const WaitAndThen = (handler = () => {}) => {
        setLoading(true)
        setTimeout(() => {
            handler()
            setLoading(false);
        }, waiting)
    }

    const waiting = 800;
    useEffect(() => {
        //setLoading(true)
        const timeout = setTimeout(() => setLoading(false), waiting)
        return () => clearTimeout(timeout)
    }, [])

    const [activeClassroomIndex, setActiveClassroomIndex] = useState(
        classroomId ? classrooms.findIndex(c => c.id === Number(classroomId)) : -1)
    const [activeStudentIndex, setActiveStudentIndex] = useState(-1)


    const onChooseClassroom = (id) => {
        history.push(MAIN_URL.MY_CLASSROOMS + '/' + id)
    }

    const [students, setStudents] = useState([])
    const [classTests, setClassTests] = useState([])

    useEffect(() => {
        if (classrooms.findIndex(c => c.id === classroomId) >= 0) {
            const studentsIds = classrooms.find(c => c.id === classroomId).students
            setStudents(users.filter(u => studentsIds.findIndex(s => s === u.id) !== -1))
            // console.log("Students", classrooms.find(c => c.id === classroomId).students)
        }
    }, [classroomId, classrooms, users])

    useEffect(() => {
        if (classrooms.findIndex(c => c.id === classroomId) >= 0) {
            const testsIds = classrooms.find(c => c.id === classroomId).tests
            setClassTests(testsIds.map(test => {
                    const info = tests.find(i => i.id === test.id)
                    return {...test, ...info}
                })
            )
            // console.log("Tests", classrooms.find(c => c.id === classroomId).tests)
        }
    }, [classroomId, classrooms, tests])

    useEffect(() => {
        if (classrooms.findIndex(c => c.id === classroomId) < 0) {
            history.push(MAIN_URL.MY_CLASSROOMS)
        }
    }, [classrooms, classroomId])




    const onChooseStudent = (id) => {
        setActiveStudentIndex(id)
        setStudentModal(true)
        // if (id > 0) {
        //     history.push(MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId + '/' + id.toString())
        // } else {
        //     if (classroomId && classroomId > 0) {
        //         history.push(MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId)
        //     } else {
        //         history.push(MAIN_URL.CLASSROOM_STATISTICS)
        //     }
        // }
    }

    useEffect(() => {
        // console.log("C_ID", classroomId)
        if (classroomId) {
            setActiveClassroomIndex(classrooms.findIndex(c => c.id === Number(classroomId)))
            // console.log("FIND", activeClassroomIndex, classrooms.findIndex(c => c.id === Number(classroomId)))
        } else {
            setActiveClassroomIndex(-1)
        }
    }, [classroomId, classrooms])

    // useEffect(() => {
    //     if (studentId) {
    //         try {
    //             setActiveStudentIndex(students
    //                 .findIndex(s => s.id === studentId))
    //         } catch (e) {
    //             setActiveStudentIndex(-1)
    //         }
    //     } else {
    //         setActiveStudentIndex(-1)
    //     }
    // }, [studentId])
    
    const [modalAddClassroom, setModalAddClassroom] = useState(false)
    const [classroomName, setClassroomName] = useState("")
    const [classroomStudent, setClassroomStudent] = useState("")
    const [classroomStudents, setClassroomStudents] = useState([])
    const [classroomAssistants, setClassroomAssistants] = useState([])
    const [classroomAddError, setClassroomAddError] = useState("")

    const [editClassroomMode, setEditClassroomMode] = useState(false)

    useEffect(() => {
        if (editClassroomMode)
            setClassroomStudents(students)
    }, [students, editClassroomMode])

    useEffect(() => {
        if (editClassroomMode) {
            setClassroomName(classrooms.find(c => c.id === classroomId).name)
        }
    }, [editClassroomMode, classrooms])

    // const [modalAddStudent, setModalAddStudent] = useState(false)

    const [showGroupInfo, setShowGroupInfo] = useState(false)
    
    const OnAddNewClassroom = () => {
        setClassroomAddError("")
        if (classroomName.trim().length === 0) {
            setClassroomName(n => n.trim())
            setClassroomAddError("Введите название группы")
            return 
        }
        dispatch(addClassroom({name: classroomName.trim(), students: classroomStudents.map(s => s.id)}))
        setModalAddClassroom(false)
        setClassroomStudent("")
        setClassroomName("")
        setClassroomStudents([])
        // setClassroomName(n => n.trim())
    }

    const onAddStudent = (user) => {
        setIsFocusOnStudent(false)
        setClassroomStudents(s => [user, ...s])
        setClassroomStudent("")
    }

    const [isFocusOnStudent, setIsFocusOnStudent] = useState(false)

    const [studentModal, setStudentModal] = useState(false)

    const [testModal, setTestModal] = useState(false)
    const [testName, setTestName] = useState("")
    const [isFocusOnTest, setIsFocusOnTest] = useState(false)

    const onAddTest = (test) => {
        setIsFocusOnTest(false)
        dispatch(addTestToClassroom({classroomId, testId: test.id}))
        setTestName("")
        setTestModal(false)
    }

    const [modalTestProgress, setModalTestProgress] = useState(false)
    const [activeTest, setActiveTest] = useState({name: "", attempts: 0, progress: 0, id: -1})

    const [progressArray, setProgressArray] = useState(students.map(i => 0))

    useEffect(() => {
        setProgressArray(students.map(s => Math.round(Math.random() * 100)))
    }, [students])

    return <div>
        {/*<div className={styles.full_white_screen}/>*/}
        <AppModal modalVisible={studentModal} setModalVisible={setStudentModal}>
            <div className={styles.stat_header_section_name}>
                Информация об ученике
            </div>
            {activeStudentIndex >= 0 && students.length > 0 &&
            <AppInput value={students.find(s => s.id === activeStudentIndex).name}
                      label="Имя"
                      editable={false}
            />}
            {/*<div className={styles.add_classroom_modal_useful_div}>*/}
                <AppButton
                    onClick={() => history.push(MAIN_URL.CLASSROOM_STATISTICS + '/' + classroomId
                        + '/' + activeStudentIndex)}
                >Смотреть статистику ученика</AppButton>
                <AppButton onClick={() => {
                    WaitAndThen(() => {
                        setActiveStudentIndex(-1)
                        dispatch(deleteUserFromClassroom({classroomId, userId: activeStudentIndex}))
                        setStudentModal(false)
                    })
                }}>
                    Удалить ученика из группы
                </AppButton>
            {/*</div>*/}
            <AppButton onClick={() => {
                //setActiveStudentIndex(-1)
                setStudentModal(false)
            }} type="red">
                Закрыть
            </AppButton>
        </AppModal>
        <AppModal modalVisible={modalAddClassroom} setModalVisible={setModalAddClassroom}>
            <div className={styles.stat_header_section_name}>
                {editClassroomMode ? "Редактирование группы" : "Добавление новой группы"}
            </div>
            <div className={styles.add_classroom_modal_useful_div}>
            <AppInput value={classroomName} setValue={setClassroomName}
                      label="Название группы"
                      placeholder={"Введите название группы"}
            />
            <div className={styles.input_student_div}>
                <AppInput value={classroomStudent} setValue={setClassroomStudent}
                          label="Студент"
                          placeholder={"Введите имя ученика"}
                          onFocus={() => setIsFocusOnStudent(true)}
                          //onBlur={() => setIsFocusOnStudent(false)}
                />
                <div className={styles.students_modal_choose_div}>
                {isFocusOnStudent ? <div className={styles.students_choose_list}>
                    {users
                        .filter(u => u.name.includes(classroomStudent))
                        .filter(u => classroomStudents.findIndex(s => s.id === u.id) === -1)
                        .slice(0, 10)
                        .map((u, i) => (
                            <div key={i.toString()}
                                 className={styles.student_in_choose_list}
                                 onClick={() => onAddStudent(u)}
                            >{u.name}
                            </div>
                        ))}
                </div> : <div/>}
                </div>
                <div className={styles.student_list_header_text}>
                    Ученики в группе
                </div>
            </div>
                <div className={styles.modal_students_list}>
                    {classroomStudents.length > 0 ? classroomStudents.map((s, i) => (
                        <div className={styles.modal_student} key={i.toString()}>
                            <div className={styles.modal_student_name}>{s.name}</div>
                            <div className={styles.modal_student_delete}
                                 onClick={() => {
                                     // editClassroom ?
                                     //     dispatch(deleteUserFromClassroom({classroomId, userId: s.id})) :
                                     setClassroomStudents(list => list.filter(u => u.id !== s.id))
                                 }}
                            >Удалить</div>
                        </div>
                    )) : <div className={styles.modal_student_name}>Пока не добавлено ни одного ученика</div>}
                </div>
            </div>
            <div className={styles.modal_error_and_button_div}>
                <div className={styles.modal_inner_error}>
                    {classroomAddError}
                </div>
                <AppButton onClick={() => {
                    if (editClassroomMode) {
                        setClassroomAddError("")
                        if (classroomName.trim().length === 0) {
                            setClassroomName(n => n.trim())
                            setClassroomAddError("Введите название группы")
                            return
                        }
                        dispatch(editClassroom({id: classroomId,
                            info: {name: classroomName.trim(), students: classroomStudents.map(s => s.id)}}))
                        setModalAddClassroom(false)
                        setClassroomStudent("")
                        setClassroomName("")
                        setClassroomStudents([])
                    } else {
                        OnAddNewClassroom()
                    }
                    setEditClassroomMode(false)
                }}>
                    Сохранить
                </AppButton>
            </div>
        </AppModal>
        <AppModal modalVisible={testModal} setModalVisible={setTestModal}>
            <div className={styles.stat_header_section_name}>
                Выставить новый тест группе
            </div>
            <div className={styles.add_classroom_modal_useful_div}>
                <div className={styles.input_student_div}>
                    <AppInput value={testName} setValue={setTestName}
                              label="Тест"
                              placeholder={"Введите название теста"}
                              onFocus={() => setIsFocusOnTest(true)}
                        //onBlur={() => setIsFocusOnStudent(false)}
                    />
                    <div className={styles.students_modal_choose_div}>
                        {isFocusOnTest ? <div className={styles.students_choose_list}>
                            {tests
                                .filter(t => t.name.includes(testName))
                                .filter(t => classTests.findIndex(c => c.id === t.id) === -1)
                                .slice(0, 10)
                                .map((t, i) => (
                                    <div key={i.toString()}
                                         className={styles.student_in_choose_list}
                                         onClick={() => onAddTest(t)}
                                    >{t.name}
                                    </div>
                                ))}
                        </div> : <div/>}
                    </div>
                </div>
            </div>
            <div className={styles.modal_error_and_button_div}>
                <AppButton onClick={() => {
                    setIsFocusOnTest(false)
                    setTestName("")
                    setTestModal(false)
                }} type="red">
                    Закрыть
                </AppButton>
            </div>
        </AppModal>
        <AppModal modalVisible={modalTestProgress} setModalVisible={setModalTestProgress}>
            <div className={styles.stat_header_section_name}>
                {`Результаты прохождения теста ${activeTest.name}`}
            </div>
            <div className={cn(styles.add_classroom_modal_useful_div)}>
                <div className={styles.input_student_div}>
                    <div className={styles.student_list_header_text}>
                        {`Средний прогресс: ${activeTest.progress}`}
                    </div>
                    <div className={styles.student_list_header_text}>
                        {`Всего попыток: ${activeTest.attempts}`}
                    </div>
                    <div className={styles.student_list_header_text}>
                        Прогресс по ученикам
                    </div>
                </div>
                <div className={cn(styles.modal_students_list, styles.modal_tests_list)}>
                    {students.length > 0 && progressArray.length > 0 ? students.map((s, i) => (
                        <div className={styles.modal_student} key={i.toString()}>
                            <div className={styles.modal_student_name}>{s.name}</div>
                            <div style={{width: '90%', maxWidth: '200px'}}>
                            <ProgressLine done={
                                activeTest.attempts === 0 ? 0 : progressArray[i]}/>
                            </div>
                        </div>
                    )) : <div className={styles.modal_student_name}>Пока нет ни одного ученика</div>}
                </div>
            </div>
            <div className={styles.modal_error_and_button_div}>
                <AppButton onClick={() => {
                    setModalTestProgress(false)
                }} type={"red"}>
                    Закрыть
                </AppButton>
            </div>
        </AppModal>

        <AppModal modalVisible={loading} setModalVisible={setLoading} canCloseOnBackdrop={false}
                  showLoading={true}
        />

        <div className={styles.stat_header}>
            <div className={styles.stat_header_section_name}>
                {classroomId ? "Выставленные тесты" : ""}
            </div>
            {classroomId ? <div className={styles.stat_time}>
                <AppButton onClick={() => setShowGroupInfo(i => !i)}>
                    Информация о группе
                </AppButton>
            </div> : <div/>}
        </div>

        {classroomId ? <div className={cn(styles.choose_stat_type_block_second,
            showGroupInfo && styles.active_second_block)}>
            <div className={styles.choose_stat_type}>
                {/*<div className={styles.classroom_list_header_text} style={{fontSize: "20px"}}>*/}
                {/*    Редактировать данные*/}
                {/*</div>*/}
                {/*{GetStudentItem(*/}
                {/*    {*/}
                {/*        name: "Сводная",*/}
                {/*        id: -1,*/}
                {/*        index: -1,*/}
                {/*        //active: -1 === activeStudentIndex,*/}
                {/*        onClick: () => WaitAndThen(() => onChooseStudent(-1))*/}
                {/*    })*/}
                {/*}*/}
                <div onClick={() => {
                    setEditClassroomMode(true)
                    setModalAddClassroom(true)
                }}
                     className={cn(styles.student_item_div, styles.classroom_list_header_text)}
                     style={{fontSize: "16px", marginBottom: "0"}}
                >
                    Редактировать данные
                </div>
                <div className={styles.classroom_list_header_text} style={{fontSize: "16px"}}>
                    {`Ученики (${students.length})`}
                </div>
                {students.map((student, index) => {
                    return GetStudentItem(
                        {
                            name: student.name,
                            id: student.id,
                            index,
                            //active: index === activeStudentIndex,
                            onClick: () => WaitAndThen(() => onChooseStudent(student.id))
                        })
                })}
            </div>
        </div> : ''}
        <div className={styles.choose_stat_type_block}>
            <div className={styles.choose_stat_type}>
                <div className={styles.classroom_list_header_text}>
                    Группы
                </div>
                {GetClassroomItem(
                    {
                        name: "+ Добавить",
                        id: 0,
                        index: -2,
                        active: false,
                        onClick: () => setModalAddClassroom(true)
                    })}
                {classrooms.map((classroom, index) => {
                    return GetClassroomItem(
                        {
                            name: classroom.name,
                            id: classroom.id,
                            index,
                            active: index === activeClassroomIndex,
                            onClick: () => WaitAndThen(() => onChooseClassroom(classroom.id))
                        })
                })}
            </div>
        </div>


        <div className={styles.main_content}>
            {classroomId ?
                <div className={styles.div_tests_list}>
                    <div className={cn(styles.test_white_block_div, styles.blue_border)}
                         onClick={() => setTestModal(true)}>
                        <div style={{}} className={styles.blue_color}
                        >{"+ Добавить"}</div>
                    </div>
                    {classTests.map((test, i) => {
                        return <div
                            key={i.toString()}
                            className={styles.test_white_block_div}
                            onClick={() => {
                                setActiveTest(test)
                                setModalTestProgress(true)
                            }}
                        >
                            <h6 style={{}}>{test.name}</h6>
                            {/*<h6>{test.progress.toString() + '%'}</h6>*/}
                            <div style={{maxWidth: '270px', width: '100%'}}>
                                <ProgressLine done={test.progress}/>
                            </div>
                        </div>

                    })}
                </div>
                :
                <div className={styles.black_div}/>
            }
        </div>

        <div className={cn(styles.full_screen_shadow, showGroupInfo && styles.full_scale)}
             onClick={() => setShowGroupInfo(false)}
        />
    </div>
}

export default TeacherClassroomsPage;
