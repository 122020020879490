import React, {useState} from "react";
import styles from './styles.module.scss';

import cn from 'classnames';
import {AppWhiteBlock} from "../AppWhiteBlock";

const AppSelectedList = (
    {
        data,
        activeIndex,
        setActiveIndex,
        active=true,
        width="200px",
        nameColumn = "name",
        onChoose = () => {},
        listStyle = {},
        elementStyle = {}
    }) => {

    const [showList, setShowList] = useState(false);

    const onClickElem = (id) => {
        setActiveIndex(id);
        setShowList(false);
        onChoose(id);
    }

    return <div className={cn(styles.main_div, !active && styles.main_inactive)} style={{minWidth: width}}>
        <div className={cn(styles.main_block)} onClick={() => {
            if (active) setShowList(s => !s)
        }}>
            <div className={styles.main_element}>
                {data[activeIndex][nameColumn]}
            </div>
            {/*<button onClick={() => setShowList(s => !s)}>*/}
            {/*    K*/}
            {/*</button>*/}
        </div>
    <div className={cn(styles.list_block, !showList && styles.not_shown_list)} style={listStyle}>
        {data.map((elem, i) =>
            <div onClick={() => onClickElem(i)}
                className={styles.list_element}
                key={elem.key} style={elementStyle}
            >
                {elem[nameColumn]}
            </div>
        )}
    </div>
    </div>;

};


export default AppSelectedList;
