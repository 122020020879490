import React, {useEffect, useState} from 'react';
import styles from "./../styles.module.scss";
import AdminStatisticsPage, {
    GetTime,
    METRICS_NAMES,
    SimpleGraphDataRequest,
    statMeanBigLabel,
    statMeanLabel
} from "../index";
import AppGraph from "../../../components/AppGraph";
import RequestWrapper from "../../../helpers/RequestWrapper";
import {useSelector} from "react-redux";
import {DB_PATH, METRICS} from "../../../constants";
import AppSelectedList from "../../../components/AppSelectedList";
import AppButton from "../../../components/AppButton";


export const ConversionDataRequest = (R, first, second, setD, label, time) => {
    R.GET({request: DB_PATH.STATISTICS.METRIC.N +
            `?metric=conversion&first=${first.name}&second=${second.name}&time=${time}`,
        meta: {ms: 300000}}).then(res => {
        try {
            const xAxes = res.json.data.map(elem => GetTime({userDate: elem.d}))
            setD([
                {
                    label: "Доля",
                    secondaryAxisID: 'first',
                    bigLabel: `Доля из "${first.bigLabel}" в "${second.bigLabel}"`,
                    config: {type: "gray"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.v}))
                },
                {
                    label: first.label,
                    secondaryAxisID: 'second',
                    bigLabel: first.bigLabel,
                    config: {color: "#FA0000"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.c}))
                },
                {
                    label: statMeanLabel,
                    bigLabel: statMeanBigLabel,
                    config: {type: "median"},
                    data: res.json.data.map((el, i) => ({x: xAxes[i], y: el.m}))
                }
            ])
        } catch (e) {
            console.log(e)
            // setError("Error: " + e)
        }
    })
}

const SelectedFirstMetrics = [
    {id: 0, key: "0", name: METRICS.UNIQUE_USERS,
        name1: "уникальных пользователей", name2: "уникальным пользователям"},
    {id: 1, key: "1", name: METRICS.ENTERED_USERS,
        name1: "зашедших пользователей", name2: "зашедшим пользователям"},
    {id: 2, key: "2", name: METRICS.COMPLETED_PRODUCTS,
        name1: "пройденных продуктов", name2: "пройденным продуктам"},
    {id: 3, key: "3", name: METRICS.USER_COMPLETED,
        name1: "прошедших пользователей", name2: "прошедшим пользователям"},
]




const StatisticConversionsPage = ({history}) => {

    const [data, setData] = useState([])

    const {REQUEST} = RequestWrapper();

    const label = "Доля";

    const time = useSelector(state => state.statistics.time);

    const clearData = () => {
        const clearAll = () => {
            setData([]);
        }
        return Promise.resolve(clearAll())
    }
    const [first, setFirst] = useState(0)
    const [second, setSecond] = useState(0)
    const [secondId, setSecondId] = useState(1)

    const CalculateMetric = () => {
        clearData().then(res => {
            ConversionDataRequest(REQUEST, METRICS_NAMES[first],
                METRICS_NAMES[SelectedFirstMetrics.filter((e, i) => i !== first)[second].id], setData, label, time);
        });
    }

    useEffect(() => {
        CalculateMetric()
    }, [time])

    useEffect(() => {
        if (SelectedFirstMetrics[first].id ===
            SelectedFirstMetrics.filter((e, i) => i !== first)[second].id) {
            // console.log("FS", first, second)
            if (first === 0) {
                setSecond(1)
            } else {
                setSecond(0)
            }
        }
    }, [first, second])

    return <AdminStatisticsPage history={history} time={time} screenId={1}>
        <div className={styles.graphics_container}>
            <div className={styles.conversion_settings_div}>
            <div className={styles.conversion_div}>
                <div>Отношение</div>
                <AppSelectedList activeIndex={first} setActiveIndex={setFirst} nameColumn={"name1"}
                                 data={SelectedFirstMetrics}/>
                <div>к</div>
                <AppSelectedList activeIndex={second} setActiveIndex={setSecond} nameColumn={"name2"}
                                 data={SelectedFirstMetrics.filter((e, i) => i !== first)}/>

            </div>
                <div style={{marginTop: "20px"}}>
                    <AppButton onClick={CalculateMetric}>Посчитать</AppButton>
                </div>
            </div>

            <div className={styles.graphic_elem}>
                <AppGraph data={data}
                          name={label}
                          primaryAxisType="time"
                />
            </div>
        </div>
    </AdminStatisticsPage>
}

export default StatisticConversionsPage;