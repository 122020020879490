import {
    ADD_CLASSROOM,
    ADD_TEST_TO_CLASSROOM,
    ADD_USER_TO_CLASSROOM, CREATE_NEW_QUESTION, CREATE_NEW_TEST,
    DELETE_TEST_FROM_CLASSROOM,
    DELETE_USER_FROM_CLASSROOM, EDIT_CLASSROOM, EDIT_QUESTION, EDIT_TEST
} from "../types";
import {PERIODS, SECTIONS} from "../../constants";

export const TESTS_TYPES = {
    COMMON: 'COMMON',
    MY: 'MY',
}

export const QUESTIONS_TYPES = {
    COMMON: 'COMMON',
    MY: 'MY',
}

const reducerLP =
    (acc, currentValue) => acc + currentValue.lp;
const reducerTP =
    (acc, currentValue) => acc + currentValue.tp;

const GetStat = () => {
    return Object
        .keys(SECTIONS)
        .map(sec => ({...SECTIONS[sec], key: sec}))
        .map((sec, i) => {
            const progress = sec.periods.map(period =>
                ({...PERIODS[period],
                    lp: Math.round(Math.random() * 100)}))
                .map(period => ({...period,
                    tp: Math.min(100, Math.round(period.lp * (1 + Math.random() / 2)))}))
            const TP = Math.round(progress.reduce(reducerTP, 0) / progress.length);
            const LP = Math.round(progress.reduce(reducerLP, 0) / progress.length);
            return {progress, TP, LP, sec}
        })
}

const initialState = {
    classrooms: [
        {
            name: "После занятий",
            id: 1,
            assistants: [2, 3],
            students: [
                1, 3, 5, 7, 9, 10, 11, 13, 14, 15, 17, 20, 21, 23, 25
            ],
            tests: [
                {
                    id: 1,
                    progress: 60,
                    expired: "",
                    attempts: 24
                },
                {
                    id: 2,
                    progress: 35,
                    expired: "",
                    attempts: 28
                },
                {
                    id: 7,
                    progress: 40,
                    expired: "",
                    attempts: 15
                },
                {
                    id: 5,
                    progress: 77,
                    expired: "",
                    attempts: 19
                },
                {
                    id: 6,
                    progress: 96,
                    expired: "",
                    attempts: 18
                },
                {
                    id: 8,
                    progress: 30,
                    expired: "",
                    attempts: 30
                },
                {
                    id: 13,
                    progress: 43,
                    expired: "",
                    attempts: 21
                },
                {
                    id: 17,
                    progress: 60,
                    expired: "",
                    attempts: 24
                },
                {
                    id: 19,
                    progress: 40,
                    expired: "",
                    attempts: 10
                }
            ],
            statistics: GetStat()
        },
        {
            name: "История 11А",
            id: 2,
            assistants: [1, 3],
            students: [
                6, 8, 9, 10, 11, 14, 15, 16, 17, 19, 22, 23
            ],
            tests: [
                {
                    id: 1,
                    progress: 30,
                    expired: "",
                    attempts: 20
                },
                {
                    id: 3,
                    progress: 35,
                    expired: "",
                    attempts: 28
                },
                {
                    id: 4,
                    progress: 10,
                    expired: "",
                    attempts: 5
                },
                {
                    id: 5,
                    progress: 70,
                    expired: "",
                    attempts: 39
                },
                {
                    id: 6,
                    progress: 96,
                    expired: "",
                    attempts: 15
                },
                {
                    id: 8,
                    progress: 30,
                    expired: "",
                    attempts: 30
                },
                {
                    id: 9,
                    progress: 56,
                    expired: "",
                    attempts: 29
                },
                {
                    id: 11,
                    progress: 43,
                    expired: "",
                    attempts: 21
                },
                {
                    id: 18,
                    progress: 50,
                    expired: "",
                    attempts: 24
                },
                {
                    id: 19,
                    progress: 40,
                    expired: "",
                    attempts: 10
                }
            ],
            statistics: GetStat()
        }
    ],
    tests: [
        {
            id: 1,
            name: "Петр I (Часть 1)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 2,
            name: "Петр I (Часть 2)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 3,
            name: "Петр I (Часть 3)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 4,
            name: "Ленин (часть 1)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 5,
            name: "Ленин (часть 2)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 6,
            name: "Ленин (часть 3)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 7,
            name: "Ленин (часть 4)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 8,
            name: "Ленин (часть 5)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 9,
            name: "Путин и Медведев (часть 1)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 10,
            name: "Путин и Медведев (часть 2)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 11,
            name: "Путин и Медведев (часть 3)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 12,
            name: "Ельцин (часть 1)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 13,
            name: "Ельцин (часть 2)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 14,
            name: "Ельцин (часть 3)",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 15,
            name: "Политика Хрущева",
            type: TESTS_TYPES.COMMON,
            questions: []
        },
        {
            id: 16,
            name: "Культура 9 века",
            type: TESTS_TYPES.MY,
            questions: []
        },
        {
            id: 17,
            name: "Культура 10 века",
            type: TESTS_TYPES.MY,
            questions: []
        },
        {
            id: 18,
            name: "Культура 11 века",
            type: TESTS_TYPES.MY,
            questions: []
        },
        {
            id: 19,
            name: "Живопись 14 века",
            type: TESTS_TYPES.MY,
            questions: []
        },
        {
            id: 20,
            name: "Живопись 15 века",
            type: TESTS_TYPES.MY,
            questions: []
        },
    ],
    users: [
        {
            name: "Варя Огорькова",
            id: 1
        },
        {
            name: "Валя Толкина",
            id: 2
        },
        {
            name: "Денис Неверов",
            id: 3
        },
        {
            name: "Женя Оленин",
            id: 4
        },
        {
            name: "Анастасия Павлова",
            id: 5
        },
        {
            name: "Анатолий Малькута",
            id: 6
        },
        {
            name: "Коля Серый",
            id: 7
        },
        {
            name: "Наташа Чернова",
            id: 8
        },
        {
            name: "Иван Бунин",
            id: 9
        },
        {
            name: "Артём Зарипов",
            id: 10
        },
        {
            name: "Мария Демидова",
            id: 11
        },
        {
            name: "Артём Александров",
            id: 12
        },
        {
            name: "Антон Ильин",
            id: 13
        },
        {
            name: "Денис Ефремов",
            id: 14
        },
        {
            name: "Наталия Тюленева",
            id: 15
        },
        {
            name: "Марина Парентьева",
            id: 16
        },
        {
            name: "Георгий Черданцев",
            id: 17
        },
        {
            name: "Алексей Афонин",
            id: 18
        },
        {
            name: "Елена Лицарева",
            id: 19
        },
        {
            name: "Мария Слепцова",
            id: 20
        },
        {
            name: "Галина Федоренко",
            id: 21
        },
        {
            name: "Катя Рябина",
            id: 22
        },
        {
            name: "Дарья Троян",
            id: 23
        },
        {
            name: "Ира Новикова",
            id: 24
        },
        {
            name: "Влад Краснопёров",
            id: 25
        },
        {
            name: "Женя Волков",
            id: 26
        },
        {
            name: "Игорь Дробышев",
            id: 27
        },
        {
            name: "Николай Харченко",
            id: 28
        },
        {
            name: "Зоя Сузина",
            id: 29
        },
        {
            name: "Антон Белоусов",
            id: 30
        },
        {
            name: "Игорь Кикнадзе",
            id: 31
        },
        {
            name: "Юрий Орлов",
            id: 32
        },
        {
            name: "Павел Белов",
            id: 33
        },
    ],
    questions: [
        {
            id: 1,
            question: "В каком году произошло Полтавское сражение?",
            answers: ["1709", "1702", "1714", "1711"],
            valid_answers: ["1709"],
            type: QUESTIONS_TYPES.COMMON
        }
    ],
    loading: false,
};

const reducer = (accumulator, currentValue) => Math.max(accumulator, currentValue.id);

export const ClassroomReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CLASSROOM:
            const maxId3 = state.classrooms.reduce(reducer, 0) + 1;
            // console.log("ADD! id:", maxId3)
            return {
                ...state,
                classrooms: [{
                    id: maxId3,
                    name: "#NoName",
                    students: [],
                    assistants: [],
                    tests: [],
                    statistics: GetStat(),
                    ...action.payload
                }, ...state.classrooms]
            }
        case EDIT_CLASSROOM:
            return {
                ...state,
                classrooms: state.classrooms.map(classroom => {
                    if (classroom.id === action.payload.id) {
                        return {...classroom, ...action.payload.info}
                    }
                    return classroom
                })
            }
        case ADD_USER_TO_CLASSROOM:
            return {
                ...state,
                classrooms: state.classrooms.map((classroom) => {
                    if (classroom.id === action.payload.classroomId) {
                        if (classroom.students.findIndex(s => s === action.payload.userId) < 0) {
                            classroom.students.push(action.payload.userId)
                        }
                    }
                    return classroom
                }),
                loading: false,
            };
        case DELETE_USER_FROM_CLASSROOM:
            return {
                ...state,
                classrooms: state.classrooms.map((classroom) => {
                    if (classroom.id === action.payload.classroomId) {
                        const newStudents = classroom.students.filter(s => s !== action.payload.userId);
                        return {...classroom, students: newStudents}
                    }
                    return classroom
                }),
            }
        case ADD_TEST_TO_CLASSROOM:
            return {
                ...state,
                classrooms: state.classrooms.map((classroom) => {
                    if (classroom.id === action.payload.classroomId) {
                        classroom.tests = [{id: action.payload.testId, progress: 0, attempts: 0, expired: ""},
                            ...classroom.tests]
                    }
                    return classroom
                }),
            }
        case DELETE_TEST_FROM_CLASSROOM:
            return {
                ...state,
                classrooms: state.classrooms.map((classroom) => {
                    if (classroom.id === action.payload.classroomId) {
                        const newTests = classroom.tests.filter(t => t.id !== action.payload.testId)
                        return {...classroom, tests: newTests}
                    }
                    return classroom
                }),
            }
        case CREATE_NEW_TEST:
            const maxId = state.tests.reduce(reducer, 0) + 1;
            return {
                ...state,
                tests: [...state.tests,
                    {id: maxId, type: TESTS_TYPES.MY, name: "", questions: [], ...action.payload}
                    ]
            }
        case EDIT_TEST:
            return {
                ...state,
                tests: state.tests.map(test => {
                    if (test.id === action.payload.id) {
                        return {...test, ...action.payload.info}
                    }
                    return test
                })
            }
        case CREATE_NEW_QUESTION:
            const maxId2 = state.questions.reduce(reducer, 0) + 1;
            return {
                ...state,
                questions: [...state.questions,
                    {id: maxId2, type: QUESTIONS_TYPES.MY,
                        question: "",
                        valid_answers: [],
                        answers: [], ...action.payload}
                ]
            }
        case EDIT_QUESTION:
            return {
                ...state,
                questions: state.questions.map(question => {
                    if (question.id === action.payload.id) {
                        return {...question, ...action.payload.info}
                    }
                    return question
                })
            }
        default:
            return state;
    }
};
