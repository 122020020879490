import React, {} from "react";
import {Route, Switch, Redirect} from 'react-router-dom';
import {MAIN_URL} from "../constants";
import ClassroomPage from "../pages/ClassroomPage";
import ProfilePage from "../pages/ProfilePages/ProfilePage";
import CompetitionPage from "../pages/CompetitionPage";
import RatingPage from "../pages/RatingPage";
import LandingPage from "../pages/LandingPage";
import AdminStatisticsRouter from "./AdminStatisticsRouter";
import StudentStatisticsPage from "../pages/StudentStatisticsPage";
import ClassroomStatisticsRouter from "./ClassroomStatisticsRouter";
import TeacherClassroomsPage from "../pages/TeacherClassroomsPage";
import LifeHacksPage from "../pages/LifeHacksPage";
import CreateTestsPage from "../pages/CreateTestsPage";


const MainRouter = ({...p}) => {

        // const MyRoute = ({component, ...props}) => {
        //     return <Route component={() => component({...p})} {...props}/>
        //     //return <Route component={RequestWrapper({children:(pr) => component({...pr})})} {...props}/>
        // }

        return <Switch>
            <Route exact path={MAIN_URL.LANDING} component={LandingPage}/>
            <Route exact path={MAIN_URL.CLASSROOM} component={ClassroomPage} />
            <Route exact path={MAIN_URL.COMPETITION} component={CompetitionPage}/>
            <Route exact path={MAIN_URL.RATING} component={RatingPage}/>
            <Route exact path={MAIN_URL.PROFILE} component={ProfilePage}/>
            <Route exact path={MAIN_URL.LIFE_HACKS} component={LifeHacksPage}/>
            <Route exact path={MAIN_URL.TEST_CREATE} component={CreateTestsPage}/>
            <Route exact path={MAIN_URL.MY_CLASSROOMS} component={TeacherClassroomsPage}/>
            <Route exact path={MAIN_URL.MY_CLASSROOMS + "/:classroomId/"} component={TeacherClassroomsPage}/>
            <Route exact path={MAIN_URL.STATISTICS} component={StudentStatisticsPage}/>
            <Route exact path={MAIN_URL.STATISTICS + "/:section/"} component={StudentStatisticsPage}/>
            <Route exact path={MAIN_URL.STATISTICS + "/:section/:period/"} component={StudentStatisticsPage}/>
            <Route exact path={MAIN_URL.STATISTICS + "/:section/:period/:person/"} component={StudentStatisticsPage}/>

            <Route path={MAIN_URL.CLASSROOM_STATISTICS} component={ClassroomStatisticsRouter}/>
            <Route path={MAIN_URL.APP_STATISTICS} component={AdminStatisticsRouter}/>
            {/*<Route exact path={MAIN_URL.ADMIN_PROFILE} component={AdminProfilePage}/>*/}

            <Redirect to={MAIN_URL.CLASSROOM}/>
        </Switch>
};


export default MainRouter;
