import React, {useState} from 'react';
import styles from "./styles.module.scss";
import AppButton from "../../components/AppButton";
import AppInput from "../../components/AppInput";
import {AppWhiteBlock} from "../../components/AppWhiteBlock";


function EnterForm({onEnter, REQUEST}) {

    const [login, setLogin] = useState("") // denis_2 // vk__103159559
    // denis13011999dXyZ
    const [password, setPassword] = useState("") // denis_2denis_2
    const [error, setError] = useState("")

    // const [cookies, setCookie] = useCookies([COOKIES_NAMES.TOKEN]);

    // function onSetToken(newToken) {
    //     setCookie(COOKIES_NAMES.TOKEN, newToken, { path: '/' }); // 2 month: maxAge: 60*60*24*30*2
    //     if (newToken) {
    //         onEnter();
    //     }
    // }

    // const [person, setPerson] = useState(ROLES.STUDENT)

    const onLogin = () => {
        console.log("Start auth");
        setError("");
        if (login === "" || password === "") {
            setError("Заполните все поля");
        } else {
            REQUEST.GET({
                request: `auth/?username=${login}&password=${password}`, meta: {login: true},
                handler: (res) => {
                    if (res.message) {
                        setError("Ошибка: " + res.message);
                    } else {
                        onEnter();
                    }
                }
            })
            // }).then((res) => {
            //     if (res.message) {
            //         setError("Ошибка: " + res.message);
            //     } else {
            //         onEnter();
                    // console.log("USER REQUEST JSON:\n", res.json)
                    // const token = res["json"]["Token"];
                    // httpGet({request: "user/", token}).then(result => {
                    //     try {
                    //         console.log("USER", result.json)
                    //         if (result.json.is_staff) {
                    //             setPerson(ROLES.ADMIN)
                    //         } else if (result.json.is_teacher) {
                    //             setPerson(ROLES.TEACHER)
                    //         }
                    //     } catch (e) {
                    //         console.log("ERR", e);
                    //     }
                    // })
                    // onSetToken(token)
                // }
            //});
        }
    }

    // const setUserInfo = () => {
    //     httpGet({request: "user/" + userName, token}).then(res => {
    //         try {
    //             console.log(res.json)
    //             setIsStaff(res.json.is_staff);
    //             const localName = res.json.first_name + ' ' + res.json.last_name;
    //             fromVK ? dispatch(updateVkUser({name: localName, userName})) : dispatch(updateUser({userName}));
    //             setLoadingUserInfo(false);
    //         } catch (e) {
    //             console.log("ERR", userName);
    //             setLoadingUserInfo(false);
    //         }
    //     })
    // };


    // useEffect(() => {
    //     console.log("token", cookies.token)
    //     //setModal(!cookies.token)
    //     if (!cookies.token) {
    //         onEnter();
    //     }
    // }, [cookies])

    return <AppWhiteBlock>
        <div className={styles.main_div}>
        <div className={styles.head_form}>Войти</div>
        {/*<div style={{width: '100%'}}>*/}
            <AppInput label="Логин" value={login} setValue={setLogin}
                      placeholder="Введите логин"/>
            <AppInput label="Пароль" value={password} setValue={setPassword}
                      placeholder="Введите пароль" type="password"/>
        {/*</div>*/}
        <div className={styles.error}>{error}</div>
        <AppButton onClick={onLogin}>Войти</AppButton>
        </div>
    </AppWhiteBlock>
}

export default EnterForm;
