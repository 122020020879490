import React, { useState } from "react";
import { ProgressLine } from "./../ProgressLine/index";
import styles from "./styles.module.scss"


export const CardAchievement = ({ all = 100, done, name, i=0, onClick = () => {} }) => {
    // const [modalVisible, setModalVisible] = useState(false);
    // const all = card.achievements.length;
    // const done = card.achievements.reduce(reducerSum, 0)
    return (
                <div className={styles.wrapper} key={i.toString()} onClick={onClick}>
                    <div className={styles.progressContainer}>
                        <div className={styles.title}>{name}</div>
                        <ProgressLine done={done} all={all} height={24} />
                    </div>
                </div>
    );
};