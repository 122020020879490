import React, {useEffect, useMemo, useState} from "react";
import styles from './styles.module.scss';
import cn from 'classnames';
import { Chart } from 'react-charts'
import {AppActivityIndicator} from "../AppActivityIndicator";
//

// const options = {
//     elementType: ["line", "area", "bar", "bubble"],
//     primaryAxisType: ["linear", "time", "log", "ordinal"],
//     secondaryAxisType: ["linear", "time", "log", "ordinal"],
//     primaryAxisPosition: ["top", "left", "right", "bottom"],
//     secondaryAxisPosition: ["top", "left", "right", "bottom"],
//     secondaryAxisStack: [true, false],
//     primaryAxisShow: [true, false],
//     secondaryAxisShow: [true, false],
//     grouping: ["single", "series", "primary", "secondary"],
//     tooltipAnchor: [
//         "closest",
//         "top",
//         "bottom",
//         "left",
//         "right",
//         "center",
//         "gridTop",
//         "gridBottom",
//         "gridLeft",
//         "gridRight",
//         "gridCenter",
//         "pointer"
//     ],
//     tooltipAlign: [
//         "auto",
//         "top",
//         "bottom",
//         "left",
//         "right",
//         "topLeft",
//         "topRight",
//         "bottomLeft",
//         "bottomRight",
//         "center"
//     ],
//     snapCursor: [true, false]
// };
//
// const optionKeys = Object.keys(options);
//
// export function useChartConfig({
//                                            series,
//                                            useR,
//                                            show = [],
//                                            count = 1,
//                                            resizable = true,
//                                            canRandomize = true,
//                                            dataType = "time",
//                                            elementType = "line",
//                                            primaryAxisType = "time",
//                                            secondaryAxisType = "linear",
//                                            primaryAxisPosition = "bottom",
//                                            secondaryAxisPosition = "left",
//                                            primaryAxisStack = false,
//                                            secondaryAxisStack = true,
//                                            primaryAxisShow = true,
//                                            secondaryAxisShow = true,
//                                            tooltipAnchor = "closest",
//                                            tooltipAlign = "auto",
//                                            grouping = "primary",
//                                            snapCursor = true,
//                                            datums = 10
//                                        }) {
//     const [state, setState] = React.useState({
//         count,
//         resizable,
//         canRandomize,
//         dataType,
//         elementType,
//         primaryAxisType,
//         secondaryAxisType,
//         primaryAxisPosition,
//         secondaryAxisPosition,
//         primaryAxisStack,
//         secondaryAxisStack,
//         primaryAxisShow,
//         secondaryAxisShow,
//         tooltipAnchor,
//         tooltipAlign,
//         grouping,
//         snapCursor,
//         datums,
//         data: makeDataFrom(dataType, series, useR, datums)
//     });
//
//     React.useEffect(() => {
//         setState(old => ({
//             ...old,
//             data: makeDataFrom(dataType, series, useR, datums)
//         }));
//     }, [count, dataType, datums, series, useR]);
//
//     const randomizeData = () =>
//         setState(old => ({
//             ...old,
//             data: makeDataFrom(dataType, series, useR, datums)
//         }));
//
//     const Options = optionKeys
//         .filter(option => show.indexOf(option) > -1)
//         .map(option => (
//             <div key={option}>
//                 {option}: &nbsp;
//                 <select
//                     value={state[option]}
//                     onChange={({ target: { value } }) =>
//                         setState(old => ({
//                             ...old,
//                             [option]:
//                                 typeof options[option][0] === "boolean"
//                                     ? value === "true"
//                                     : value
//                         }))
//                     }
//                 >
//                     {options[option].map(d => (
//                         <option value={d} key={d.toString()}>
//                             {d.toString()}
//                         </option>
//                     ))}
//                 </select>
//                 <br />
//             </div>
//         ));
//
//     return {
//         ...state,
//         randomizeData,
//         Options
//     };
// }
//
function makeDataFrom(dataType='time', series=2, useR=true, datums=8) {
    return [
        ...new Array(series || Math.max(Math.round(Math.random() * 5), 1))
    ].map((d, i) => makeSeries(i, dataType, useR, datums));
}
//
function makeSeries(i, dataType = 'linear', useR = false, datums = 10) {
    const start = 0;
    const startDate = new Date();
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    // const length = 5 + Math.round(Math.random() * 15)
    const length = datums;
    const min = 0;
    const max = 100;
    const rMin = 2;
    const rMax = 20;
    const nullChance = 0;
    return {
        label: `Series ${i + 1}`,
        data: [...new Array(length)].map((_, j) => {
            let x = j * 10 + start;
            if (dataType === "ordinal") {
                x = `Ordinal Group ${x}`;
            }
            if (dataType === "time") {
                x = new Date(startDate.getTime() + 60 * 1000 * 30 * j);
            }
            if (dataType === "linear") {
                x = j * 10
                    // Math.random() < nullChance
                    //     ? null
                    //     : min + Math.round(Math.random() * (max - min));
            }
            const distribution = 1.1;
            const y =
                Math.random() < nullChance
                    ? null
                    : min + Math.round(Math.random() * (max - min));
            const r = !useR
                ? undefined
                : rMax -
                Math.floor(
                    Math.log(Math.random() * (distribution ** rMax - rMin) + rMin) /
                    Math.log(distribution)
                );
            return {
                primary: x,
                secondary: y,
                radius: r
            };
        })
    };
}


export function AppGraph(
    {
        data,
        datumFormat= x => x,
        name="Название графика",
        primaryAxisType="linear",
        width="70%",
        height="300px",
    }) {
    const [{ activeSeriesIndex, activeDatumIndex }, setState] = React.useState({
        activeSeriesIndex: -1,
        activeDatumIndex: -1
    });

    const [activePoint, setActivePoint] = useState(false);

    return (
        <MyChart
            primaryAxisType={primaryAxisType}
            elementType="line"
            datumFormat={datumFormat}
            name={name}
            data={data}
            width={width}
            height={height}
            activePoint={activePoint}
            setActivePoint={setActivePoint}
            setState={setState}
            activeDatumIndex={activeDatumIndex}
            activeSeriesIndex={activeSeriesIndex}
        />
    );
}

const lineColors = [
    "#2447FF",
    "#F32F85",
    "#60EA72",
    "#134848",
    "#8CF9FC",
    "#EA7060",
    "#EAD360",
    "#e70fc4",
    "#6eea60",
    "#ea9760",
    "#94a0a0",
]

const grayColors = [
    "#0a0a0b",
    "#222225",
    "#3b3b40",
    "#53535a",
    "#6c6c75",
    "#85858e",
    "#a0a0a7",
    "#bababf",
    "#d5d5d8",
    "#efeff0",
]

const medianColor = "#3354FF";

export function MyChart(
    {
        data,
        datumFormat= x => x,
        primaryAxisType="linear",
        showLabels=true,
        name="Название графика",
        activePoint,
        width="70%",
        height="300px",
        setActivePoint,
        elementType="line",
        loading=false,
        activeDatumIndex,
        activeSeriesIndex,
        setState
    }) {
    //useLagRadar();

    // const { data, grouping, randomizeData } = useChartConfig({
    //     series: 4,
    //     //useR: true,
    //     height: 200,
    //     grouping: "primary",
    //     dataType: "ordinal",
    //     show: ["elementType", "grouping"]
    // });

    // "single", "series", "primary", "secondary"
    const grouping = "primary";

    const series = React.useMemo(
        () => ({
            type: elementType,
            //showPoints: false
        }),
        [elementType]
    );

    const axes = React.useMemo(
        () => [
            {
                primary: true,
                type: primaryAxisType, // "linear", "time", "log", "ordinal"
                position: "bottom",
                show: true,
            },
            {
                type: "linear",
                id: 'first',
                format: datumFormat,
                position: "left",
                stacked: false
            }],
        []
    );

    const axes3 = React.useMemo(
        () => [
            {
                primary: true,
                type: primaryAxisType, // "linear", "time", "log", "ordinal"
                position: "bottom",
                show: true,
            },
            {
                type: "linear",
                id: 'first',
                format: datumFormat,
                position: "left",
                stacked: false
            },
            {
                type: "linear",
                id: 'second',
                position: "right",
                stacked: false,
            }],
        []
    );


    const Data = useMemo(() => data, [data]);

    const [loadingMode, setLoadingMode] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoadingMode(!data || data.length === 0), 500);
    }, [data])

    const GetColor = (i) => {
        //console.log("CONF", config)
        // return medianColor
        //console.log("index", i, Data[i], Data)
        //return lineColors[i % lineColors.length]
        const config = Data[i] ? Data[i].config : undefined;
        if (config) {
            if (config.color) {
                return config.color
            } else if (config.type === "median") {
                //console.log("CONF MED", config)
                return medianColor
            } else if (config.type === "gray") {
                //console.log("CONF GRAY", config)
                return grayColors[i % grayColors.length]
            }
            //return config.color
        }
        return lineColors[i % lineColors.length]
    }

    const getSeriesStyle = React.useCallback(
        series => {
            let med = 0;
            if (data) {
                if (data[series.index].config.median) {
                    med = 1;
                }
            }
            return {
                transition: 'all 0.2s ease',
            color: GetColor(series.index), // `url(#${series.index % 4})`,
            //height: "20px",
            opacity:
                activeSeriesIndex > -1
                    ? series.index === activeSeriesIndex || med
                    ? 1
                    : 0.3
                    : 0.8
        }},
        [activeSeriesIndex, Data]
    );

    const getDatumStyle = React.useCallback(
        datum => ({
            //color: medianColor,
            transition: 'all 0.2s ease',
            r:
                activeDatumIndex === datum.index &&
                activeSeriesIndex === datum.seriesIndex
                    ? 5
                    : activeDatumIndex === datum.index
                    ? 2
                    : datum.series.index === activeSeriesIndex
                        ? 2
                        : datum.otherHovered
                            ? 1
                            : 1
        }),
        [activeDatumIndex, activeSeriesIndex]
    );

    const onFocus = React.useCallback(
        focused => {
            if (!activePoint)
            setState({
                activeSeriesIndex: focused ? focused.series.id : -1,
                activeDatumIndex: focused ? focused.index : -1
            })},
        [setState, activePoint]
    );

    const onClick = React.useCallback(
        clicked => {
            if (activePoint) {
                setActivePoint(false)
                setState({
                    activeSeriesIndex: -1,
                    activeDatumIndex: -1
                })
            } else {
                setActivePoint(true);
                setState({
                    activeSeriesIndex: clicked ? clicked.series.id : -1,
                    activeDatumIndex: clicked ? clicked.index : -1
                })
            }
        },
        [setState, activePoint, setActivePoint]
    );

    // if (!Data.length) {
    //     return <div className={styles.loading_div} style={{height, width}}>
    //         <AppActivityIndicator type="small"/>
    //     </div>
    // }

    const [LoadingData, setLoadingData] = useState(makeDataFrom());

    useEffect(() => {
        if (loadingMode) {
            setTimeout(() => setLoadingData(makeDataFrom()), 1000)
        }
    }, [loadingMode, LoadingData])

    const loadingSeries = React.useMemo(
        () => ({
            type: "bar",
            showPoints: false
        }),
        []
    );

    const getSeriesLoadingStyle = React.useCallback(
        () => ({
            transition: 'all 1.0s ease'
        }),
        []
    )
    const getDatumLoadingStyle = React.useCallback(
        () => ({
            transition: 'all 1.0s ease'
        }),
        []
    )

    const loadingAxes = React.useMemo(
        () => [
            {
                primary: true,
                type: "linear", // "linear", "time", "log", "ordinal"
                position: "bottom",
                show: false,
            },
            {
                type: "linear",
                position: "left",
                stacked: true,
                show: false,
            }
        ],
        []
    );

    const GetWaiting = () => {

        // return <Chart
        //     data={LoadingData}
        //     series={loadingSeries}
        //     axes={loadingAxes}
        //     getSeriesStyle={getSeriesLoadingStyle}
        //     getDatumStyle={getDatumLoadingStyle}
        //     //tooltip
        //     />

        return <div className={styles.loading_div} style={{height, width}}>
            <AppActivityIndicator type="small"/>
        </div>
    }

    return (
        <div className={styles.chart_container}>
            <div className={styles.graph_name}>
                {name}
            </div>
            <div style={{width, height}}>
                { loadingMode || loading ? GetWaiting() :
                    <Chart
                        data={Data}
                        //primaryAxisType={primaryAxisType}
                        grouping={grouping}
                        series={series}
                        axes={data.findIndex(el => el.secondaryAxisID === 'second') >= 0 ? axes3 : axes}
                        getSeriesStyle={getSeriesStyle}
                        getDatumStyle={getDatumStyle}
                        onFocus={onFocus}
                        onClick={onClick}
                        //primaryCursor
                        secondaryCursor
                        tooltip={{anchor: "gridRight", color: "white"}}
                        // renderSVG={() => (
                        //     <defs>
                        //         {data.map((elem, i) =>
                        //             <linearGradient
                        //                 //color={GetColor(elem.config, i)}
                        //                 key={i.toString()} id={i.toString()} x1="0" x2="0" y1="1" y2="0">
                        //                 <stop offset="0%"
                        //                       stopColor={GetColor(i)}
                        //                       //color={GetColor(elem.config, i)}
                        //                 />
                        //                 <stop offset="100%"
                        //                       stopColor={GetColor(i)}
                        //                       //color={GetColor(elem.config, i)}
                        //                 />
                        //             </linearGradient>
                        //         )}
                        //     </defs>
                        // )}
                    />}
            </div>
            {showLabels && data ?
                <div className={styles.show_labels_container} style={{width}}>
                    {data.map((elem, i) =>
                        <div className={styles.label_object} key={i.toString()}>
                            <div className={styles.colored_label_line} style={{backgroundColor: GetColor(i)}}/>
                            <div className={styles.label_name}>{elem.bigLabel}</div>
                        </div>
                    )}
                </div>
                : <div/>
            }
        </div>
    );
}

export default AppGraph;
