import {
    ADD_CLASSROOM,
    ADD_TEST_TO_CLASSROOM,
    ADD_USER_TO_CLASSROOM, CREATE_NEW_QUESTION, CREATE_NEW_TEST,
    DELETE_TEST_FROM_CLASSROOM,
    DELETE_USER_FROM_CLASSROOM, EDIT_CLASSROOM, EDIT_QUESTION, EDIT_TEST
} from "../types";


// data = {name, students: [...], assistants: [...], tests: [...]}
export const addClassroom = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_CLASSROOM,
            payload: data,
        });
    };
};

// data = {id, info: {...}}
export const editClassroom = (data) => {
    return async (dispatch) => {
        dispatch({
            type: EDIT_CLASSROOM,
            payload: data,
        });
    };
};

// data = {classroomId, userId}
export const addUserToClassroom = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_USER_TO_CLASSROOM,
            payload: data,
        });
    };
};

// data = {classroomId, userId}
export const deleteUserFromClassroom = (data) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_USER_FROM_CLASSROOM,
            payload: data,
        });
    };
};

// data = {classroomId, testId}
export const addTestToClassroom = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_TEST_TO_CLASSROOM,
            payload: data,
        });
    };
};

// data = {classroomId, testId}
export const deleteTestFromClassroom = (data) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_TEST_FROM_CLASSROOM,
            payload: data,
        });
    };
};

// data = {name: string, questions: [..., questionId: int, ...]}
// example: {name: "Петр I", questions: [2, 3, 7, 15, 18, 59, 60, 61]}
export const createNewTest = (data) => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_NEW_TEST,
            payload: data,
        });
    };
};

// data = {id: int, info: {...<info for edit>}}
// example: {id: 5, info: {name: "Петр I (часть 1)"}}
export const editTest = (data) => {
    return async (dispatch) => {
        dispatch({
            type: EDIT_TEST,
            payload: data,
        });
    };
};

// data = {question: string, answers: [..., string, ...], valid_answers: [..., string, ...]}
export const createNewQuestion = (data) => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_NEW_QUESTION,
            payload: data,
        });
    };
};

// data = {id: int, info: {...<info for edit>}}
// example: {id: 15, info: {valid_answers: ["1786 год"]}}
export const editQuestion = (data) => {
    return async (dispatch) => {
        dispatch({
            type: EDIT_QUESTION,
            payload: data,
        });
    };
};