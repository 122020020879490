import React, {useEffect} from 'react';
import styles from "./styles.module.scss";


function LifeHacksPage() {

    useEffect(() => {
        document.title = "История | Лайфхаки";
    }, [])

    return <div>
        <h1>Лайфхаки</h1>
    </div>
}

export default LifeHacksPage;
