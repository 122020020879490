import React from "react";
import styles from "./styles.module.scss";

export const AppLogo = () => {

    return <div className={styles.container}>
        <img alt="logo"
            src={"https://wmpics.pics/di-1NNS3.png"}
        />
    </div>
};