import React, {useEffect} from 'react';
import styles from "./styles.module.scss";


function CreateTestsPage() {

    useEffect(() => {
        document.title = "Конструктор тестов";
    }, [])

    return <div>
        <h1>Конструктор тестов</h1>
    </div>
}

export default CreateTestsPage;
