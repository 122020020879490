export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const VK_USER = 'VK_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_TOKEN_FROM_DB = 'SET_TOKEN_FROM_DB';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const SET_ERROR_TOKEN = 'SET_ERROR_TOKEN';
export const SET_ACTIVE_LESSON_STATE = 'SET_ACTIVE_LESSON_STATE';

// CONTENT
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';

// STATISTICS
export const SET_TIME = 'SET_TIME'
export const SET_MENU_ELEMENT_ID = 'SET_MENU_ELEMENT_ID'

// CLASSROOMS
export const ADD_CLASSROOM = 'ADD_CLASSROOM'
export const EDIT_CLASSROOM = 'EDIT_CLASSROOM'
export const ADD_USER_TO_CLASSROOM = 'ADD_USER_TO_CLASSROOM'
export const DELETE_USER_FROM_CLASSROOM = 'DELETE_USER_FROM_CLASSROOM'
export const ADD_TEST_TO_CLASSROOM = 'ADD_TEST_TO_CLASSROOM'
export const DELETE_TEST_FROM_CLASSROOM = 'DELETE_TEST_FROM_CLASSROOM'
export const CREATE_NEW_TEST = 'CREATE_NEW_TEST'
export const EDIT_TEST = 'EDIT_TEST'
export const CREATE_NEW_QUESTION = 'CREATE_NEW_QUESTION'
export const EDIT_QUESTION = 'EDIT_QUESTION'
