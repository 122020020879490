import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import {createBrowserHistory} from 'history';
// import {useRouterHistory, Router, Route} from 'react-router';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import MainRouter from "./routers";
import store from "./store"
import {Provider} from 'react-redux';
// import { CookiesProvider as Provider } from 'react-cookie';
import Content from "./components/Content";
import {START_URL} from "./constants";


ReactDOM.render(
    // <Provider>
        <Provider store={store}>
            {/*<Context.Provider value={startContext}>*/}
            {/*<HashRouter basename={process.env.PUBLIC_URL}>*/}
        {/*<RequestWrapper children={(props) => Content({...props, children:*/}
        {/*        <BrowserRouter>*/}
        {/*            <MainRouter/>*/}
        {/*        </BrowserRouter>*/}
        {/*})}/>*/}
        <BrowserRouter basename={START_URL}>
            {/*<RequestWrapper children={(props) => Content({...props, children: MainRouter})}/>*/}
            <Content children={MainRouter}/>
        </BrowserRouter>
        {/*</HashRouter>*/}
        {/*    </Context.Provider>*/}
    </Provider>
,
document.getElementById('root'));

