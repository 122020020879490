import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

export const AppWhiteBlock = ({ children, style={}, className}) => {

    return (
        <div className={cn(styles.block, className)} style={{...style}}>
            {children}
        </div>
    );
};
