import React, {} from "react";
import {Route, Switch} from 'react-router-dom';
import {MAIN_URL} from "../constants";
import ClassroomStatisticsPage from "../pages/ClassroomStatisticsPage";
import StudentStatisticsPage from "../pages/StudentStatisticsPage";


const ClassroomStatisticsRouter = () => {

    return <Switch>
        <Route exact path={MAIN_URL.CLASSROOM_STATISTICS}
               component={ClassroomStatisticsPage}/>
        <Route exact path={MAIN_URL.CLASSROOM_STATISTICS + "/:classroomId/"}
               component={ClassroomStatisticsPage}/>
        <Route exact path={MAIN_URL.CLASSROOM_STATISTICS + "/:classroomId/:studentId/"}
               component={ClassroomStatisticsPage}/>
        <Route exact path={MAIN_URL.CLASSROOM_STATISTICS + "/:classroomId/:studentId/:section/"}
               component={ClassroomStatisticsPage}/>
        <Route exact path={MAIN_URL.CLASSROOM_STATISTICS + "/:classroomId/:studentId/:section/:period/"}
               component={ClassroomStatisticsPage}/>
        <Route exact path={MAIN_URL.CLASSROOM_STATISTICS + "/:classroomId/:studentId/:section/:period/:person/"}
               component={ClassroomStatisticsPage}/>
    </Switch>
};


export default ClassroomStatisticsRouter;
